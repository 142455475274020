import { VIOLIN_TASKS, getViolinTask, getAllViolinTasks } from './violin-tasks';
import { CASHIER_TASKS, getCashierTask, getAllCashierTasks } from './cashier-tasks';
import { SOCIAL_WORKER_TASKS, getSocialWorkerTask, getAllSocialWorkerTasks } from './social-worker-tasks';
import { type Task } from '../task-types';

// Export task data
export {
  VIOLIN_TASKS,
  getViolinTask,
  getAllViolinTasks,
  CASHIER_TASKS,
  getCashierTask,
  getAllCashierTasks,
  SOCIAL_WORKER_TASKS,
  getSocialWorkerTask,
  getAllSocialWorkerTasks
};

// Core helper functions
export function getTaskById(taskId: string): Task | null {
  if (taskId in VIOLIN_TASKS) {
    const task = VIOLIN_TASKS[taskId];
    return task ?? null;
  }
  if (taskId in CASHIER_TASKS) {
    const task = CASHIER_TASKS[taskId];
    return task ?? null;
  }
  if (taskId in SOCIAL_WORKER_TASKS) {
    const task = SOCIAL_WORKER_TASKS[taskId];
    return task ?? null;
  }
  return null;
}

export function getTasksByCategory(category: Task['category']): Task[] {
  switch (category) {
    case 'violin':
      return getAllViolinTasks();
    case 'cashier':
      return getAllCashierTasks();
    case 'social-worker':
      return getAllSocialWorkerTasks();
    default:
      return [];
  }
}