import { type Task } from '../task-types';

export const SOCIAL_WORKER_TASKS: Record<string, Task> = {
  'social-patterns': {
    id: 'social-patterns',
    category: 'social-worker',
    name: 'Spot service gaps and patterns',
    description: 'Analyze complex cases to find missed opportunities',
    examples: [
      'Review past interventions for hidden patterns',
      'Map out service overlaps and gaps',
      'Find unexpected resource matches'
    ],
    augmentation: {
      type: 'exploration',
      userRole: 'Share case details and current services',
      aiRole: 'Help analyze patterns and suggest connections',
      limitations: [
        'Cannot access real-time service info',
        'Cannot make intervention decisions',
        'Cannot replace professional judgment'
      ]
    }
  },

  'social-scenarios': {
    id: 'social-scenarios',
    category: 'social-worker',
    name: 'Plan for complex scenarios',
    description: 'Think through tricky case situations systematically',
    examples: [
      'Map out multiple intervention paths',
      'Consider unusual case combinations',
      'Plan for edge-case scenarios'
    ],
    augmentation: {
      type: 'validation',
      userRole: 'Describe the complex situation',
      aiRole: 'Help explore different angles systematically',
      limitations: [
        'Cannot predict outcomes',
        'Cannot make risk assessments',
        'Cannot replace clinical judgment'
      ]
    }
  },

  'social-documentation': {
    id: 'social-documentation',
    category: 'social-worker',
    name: 'Write better case notes',
    description: 'Create clear and helpful documentation',
    examples: [
      'Document client meetings well',
      'Track how services are helping',
      'Record service connections'
    ],
    augmentation: {
      type: 'refinement',
      userRole: 'Draft your notes',
      aiRole: 'Help make them clear and objective',
      limitations: [
        'Cannot verify note content',
        'Cannot make clinical calls',
        'Cannot replace your assessment'
      ]
    }
  },

  'social-coordination': {
    id: 'social-coordination',
    category: 'social-worker',
    name: 'Coordinate care teams better',
    description: 'Keep everyone on the same page',
    examples: [
      'Connect multiple services smoothly',
      'Keep communication clear',
      'Organize care plans'
    ],
    augmentation: {
      type: 'exploration',
      userRole: 'Share your coordination needs',
      aiRole: 'Help organize and track everything',
      limitations: [
        'Cannot make medical decisions',
        'Cannot replace team discussions',
        'Cannot share private information'
      ]
    }
  }
};

// Helper functions
export function getSocialWorkerTask(taskId: keyof typeof SOCIAL_WORKER_TASKS): Task {
  const task = SOCIAL_WORKER_TASKS[taskId];
  if (!task) {
    throw new Error(`Social worker task ${taskId} not found`);
  }
  return task;
}

export function getAllSocialWorkerTasks(): Task[] {
  return Object.values(SOCIAL_WORKER_TASKS);
}