import React from 'react';
import { cn } from '@/lib/utils';

interface FileDropzoneProps {
  onFileSelect: (file: File) => void;
  accept?: string[];
  maxSize?: number;
  className?: string;
}

export function FileDropzone({
  onFileSelect,
  accept = [],
  maxSize = 5 * 1024 * 1024,
  className
}: FileDropzoneProps) {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleFileSelect = (file: File) => {
    if (maxSize && file.size > maxSize) {
      console.error(`File size exceeds ${Math.round(maxSize / (1024 * 1024))}MB limit`);
      return;
    }

    if (accept.length > 0 && !accept.some(type => file.name.endsWith(type))) {
      console.error(`File type not supported. Supported types: ${accept.join(', ')}`);
      return;
    }

    onFileSelect(file);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      handleFileSelect(file);
    }
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  return (
    <div className="relative w-full">
      <input
        ref={inputRef}
        type="file"
        className="hidden"
        accept={accept.join(',')}
        onChange={(e) => {
          const file = e.target.files?.[0];
          if (file) {
            handleFileSelect(file);
          }
        }}
        data-testid="file-input"
      />
      <div
        className={cn(
          'w-full min-h-[120px] border-2 border-dashed',
          'transition-colors duration-200 font-mono text-center p-6',
          'cursor-pointer select-none',
          'border-black/10 dark:border-white/10',
          'hover:border-black/20 dark:hover:border-white/20',
          'focus:outline-none focus-visible:ring-2',
          'focus-visible:ring-accent dark:bg-zinc-900/50',
          className
        )}
        onClick={() => inputRef.current?.click()}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            inputRef.current?.click();
          }
        }}
      >
        <div className="space-y-2">
          <p className="text-sm text-black/80 dark:text-white/80">
            <span className="font-bold">Click to upload</span>
            <span className="mx-1">or</span>
            <span className="font-bold">drag and drop</span>
          </p>
          <p className="text-xs text-black/40 dark:text-white/40">
            Supports {accept.join(', ')} up to {Math.round(maxSize / (1024 * 1024))}MB
          </p>
        </div>
      </div>
    </div>
  );
}