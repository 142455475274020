import React from 'react';
import { cn } from '@/lib/utils';
import { useStore } from '@/lib/store';
import { getTasksByCategory } from '@/lib/task-data';
import { type Task } from '@/lib/task-types';
import { TerminalText } from '@/components/ui/terminal-text';
import { ChoiceButton } from '@/components/ui/choice-button';

interface TaskSelectorProps {
  className?: string;
}

const CATEGORY_PROMPTS = {
  violin: "Violin practice can be intense. What would you like to work on?",
  cashier: "The register can get pretty hectic. Which part should we tackle?",
  "social-worker": "Social work demands a lot. Where could you use some backup?"
} as const;

export function TaskSelector({ className }: TaskSelectorProps) {
  const taskCategory = useStore(state => state.taskCategory);
  const setCurrentTask = useStore(state => state.setCurrentTask);
  const setCurrentView = useStore(state => state.setCurrentView);

  // Get tasks for current category
  const tasks = React.useMemo(() => {
    if (!taskCategory) return [];
    return getTasksByCategory(taskCategory);
  }, [taskCategory]);

  if (!taskCategory || tasks.length === 0) {
    return (
      <div className="font-mono text-sm text-center opacity-50">
        No tasks available
      </div>
    );
  }

  const handleTaskSelect = (task: Task) => {
    setCurrentTask(task.id);
    setCurrentView('task');
  };

  return (
    <div className={cn('space-y-6', className)}>
      <TerminalText
        content={CATEGORY_PROMPTS[taskCategory]}
        className="text-lg"
        speed="normal"
      />

      <div className="space-y-2">
        {tasks.map((task, index) => (
          <ChoiceButton
            key={task.id}
            indicator={String.fromCharCode(65 + index)}
            onClick={() => handleTaskSelect(task)}
          >
            <div>
              <div className="font-medium">{task.name}</div>
              <div className="text-sm text-subtitle">
                {task.description}
              </div>
              <div className="mt-2 space-y-1">
                {task.examples.map((example, i) => (
                  <div
                    key={i}
                    className="text-xs text-subheading font-mono"
                  >
                    • {example}
                  </div>
                ))}
              </div>
            </div>
          </ChoiceButton>
        ))}
      </div>
    </div>
  );
}