import { type Task } from '../task-types';

export const CASHIER_TASKS: Record<string, Task> = {
  'cashier-price-disputes': {
    id: 'cashier-price-disputes',
    category: 'cashier',
    name: 'Dealing with price questions',
    description: 'Handle price differences calmly and confidently',
    examples: [
      'When the price scans higher than the shelf',
      'When a sale sign is still up but expired',
      'When bulk discounts dont show up'
    ],
    augmentation: {
      type: 'validation',
      userRole: 'Tell me about the price situation',
      aiRole: 'Help find the best way to handle it',
      limitations: [
        'Cannot change store rules',
        'Cannot modify prices directly',
        'Cannot handle angry customers'
      ]
    }
  },

  'cashier-refunds': {
    id: 'cashier-refunds',
    category: 'cashier',
    name: 'Handling tricky returns',
    description: 'Make returns and refunds go smoothly',
    examples: [
      'Returns without a receipt',
      'Returning just part of a purchase',
      'Using gift receipts'
    ],
    augmentation: {
      type: 'exploration',
      userRole: 'Describe the return situation',
      aiRole: 'Guide you through the right steps',
      limitations: [
        'Cannot bend return rules',
        'Cannot check if items are real',
        'Cannot make manager decisions'
      ]
    }
  },

  'cashier-produce-codes': {
    id: 'cashier-produce-codes',
    category: 'cashier',
    name: 'Remembering produce codes faster',
    description: 'Speed up your produce scanning',
    examples: [
      'Tell common fruits from exotic ones',
      'Know which code to use when things look similar',
      'Get organic codes right every time'
    ],
    augmentation: {
      type: 'refinement',
      userRole: 'Share which codes you need help with',
      aiRole: 'Give you tricks to remember them',
      limitations: [
        'Cannot look at the produce',
        'Cannot check if produce is fresh',
        'Cannot replace hands-on practice'
      ]
    }
  },

  'cashier-shift-management': {
    id: 'cashier-shift-management',
    category: 'cashier',
    name: 'Switching shifts smoothly',
    description: 'Make shift changes quick and easy',
    examples: [
      'Handle sales during shift changes',
      'Close your register properly',
      'Pass on unfinished transactions'
    ],
    augmentation: {
      type: 'validation',
      userRole: 'Tell me about your shift change process',
      aiRole: 'Help you remember all the steps',
      limitations: [
        'Cannot count your drawer',
        'Cannot check register setup',
        'Cannot handle security steps'
      ]
    }
  }
};

// Helper functions
export function getCashierTask(taskId: keyof typeof CASHIER_TASKS): Task {
  const task = CASHIER_TASKS[taskId];
  if (!task) {
    throw new Error(`Cashier task ${taskId} not found`);
  }
  return task;
}

export function getAllCashierTasks(): Task[] {
  return Object.values(CASHIER_TASKS);
}