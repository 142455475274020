import { PDFDocument } from 'pdf-lib';
import { type FileUpload } from './ai-types';

/**
 * Supported file types for upload
 */
export const SUPPORTED_FILE_TYPES = {
  IMAGES: ['image/jpeg', 'image/png'] as const,
  DOCUMENTS: ['application/pdf'] as const
} as const;

type SupportedImageType = typeof SUPPORTED_FILE_TYPES.IMAGES[number];
type SupportedDocumentType = typeof SUPPORTED_FILE_TYPES.DOCUMENTS[number];
type SupportedFileType = SupportedImageType | SupportedDocumentType;

/**
 * Maximum file size in bytes (10MB)
 */
export const MAX_FILE_SIZE = 10 * 1024 * 1024;

/**
 * Converts an image buffer to PDF
 */
async function convertImageToPDF(imageData: Uint8Array, mimeType: SupportedImageType): Promise<Uint8Array> {
  const pdfDoc = await PDFDocument.create();
  const page = pdfDoc.addPage();
  
  // Convert image based on mime type
  let image;
  if (mimeType === 'image/jpeg') {
    image = await pdfDoc.embedJpg(imageData);
  } else if (mimeType === 'image/png') {
    image = await pdfDoc.embedPng(imageData);
  } else {
    throw new Error(`Unsupported image type: ${mimeType}`);
  }
  
  // Get page dimensions
  const { width: pageWidth, height: pageHeight } = page.getSize();
  
  // Calculate scaling to fit image on page while maintaining aspect ratio
  const { width: imgWidth, height: imgHeight } = image.scale(1);
  const scale = Math.min(
    pageWidth / imgWidth,
    pageHeight / imgHeight
  );
  
  // Center image on page
  const x = (pageWidth - imgWidth * scale) / 2;
  const y = (pageHeight - imgHeight * scale) / 2;
  
  // Draw image
  page.drawImage(image, {
    x,
    y,
    width: imgWidth * scale,
    height: imgHeight * scale
  });
  
  return new Uint8Array(await pdfDoc.save());
}

/**
 * Handles file conversion and preparation for Claude API
 */
export async function prepareFileForClaude(file: File): Promise<{ data: Uint8Array; mimeType: string }> {
  // Convert File to Uint8Array
  const arrayBuffer = await file.arrayBuffer();
  const data = new Uint8Array(arrayBuffer);

  // If it's an image, convert to PDF
  if (SUPPORTED_FILE_TYPES.IMAGES.includes(file.type as SupportedImageType)) {
    const pdfData = await convertImageToPDF(data, file.type as SupportedImageType);
    return {
      data: pdfData,
      mimeType: 'application/pdf'
    };
  }

  // Return PDF as-is
  if (file.type === 'application/pdf') {
    return {
      data: data,
      mimeType: file.type
    };
  }

  throw new Error(`Unsupported file type: ${file.type}`);
}

/**
 * Validates file against upload requirements
 */
export function validateFile(file: File, requirements: FileUpload): string | null {
  // Check file type
  const supportedTypes = [
    ...SUPPORTED_FILE_TYPES.IMAGES,
    ...SUPPORTED_FILE_TYPES.DOCUMENTS
  ] as SupportedFileType[];
  
  // First check if the file type is in our supported types
  const isSupported = supportedTypes.includes(file.type as SupportedFileType);
  
  // Then check if it matches the requirements
  const matchesRequirements = requirements.accept.some(type => {
    if (type.endsWith('/*')) {
      const baseType = type.slice(0, -2);
      return file.type.startsWith(baseType);
    }
    return type === file.type;
  });

  if (!isSupported || !matchesRequirements) {
    return `File type ${file.type} not supported. Allowed types: ${requirements.accept.join(', ')}`;
  }

  // Check file size
  if (file.size > requirements.maxSize) {
    return `File size ${(file.size / 1024 / 1024).toFixed(1)}MB exceeds limit of ${(requirements.maxSize / 1024 / 1024).toFixed(1)}MB`;
  }

  return null;
}