import { ChevronRight } from 'lucide-react'
import { cn } from '@/lib/utils'

interface BuildResponseProps {
  appName: string
  code: string | null
  onShowCode: (code: string) => void
  className?: string
}

export function BuildResponse({ appName, code, onShowCode, className }: BuildResponseProps) {
  return (
    <div className={cn(
      'font-mono rounded-lg overflow-hidden',
      'border-2 border-build-response', // Use new utility class for border
      'bg-build-response', // Use new utility class
      className
    )}>
      <button
        onClick={() => code && onShowCode(code)}
        className={cn(
          'w-full px-3 py-2 text-left',
          'bg-build-response-button text-build-response-button hover:bg-build-response-button-hover', // Use new utility classes
          'transition-colors duration-200',
          'flex items-center gap-2'
        )}
      >
        <ChevronRight className="h-4 w-4" />
        <div>
          <div className="font-bold">{appName}</div>
          <div className="text-sm opacity-80">Click to open the app we've built</div>
        </div>
      </button>
    </div>
  )
}