// task-data.ts
import { type Task } from '../task-types';

export const VIOLIN_TASKS: Record<string, Task> = {
  'violin-intonation': {
    id: 'violin-intonation',
    category: 'violin',
    name: 'Play more in tune',
    description: "Get help with those tricky passages that never seem quite in tune.",
    examples: [
      'Make those double stops in Bach sound cleaner.',
      'Nail those big position shifts every time.'
    ],
    augmentation: {
      type: 'validation',
      userRole: "Tell me what you're struggling with.",
      aiRole: "I'll help break it down into manageable steps.",
      limitations: [
        "I can't show you the movements.",
        "I can't hear your playing.",
        "I work alongside your teacher, not replace them."
      ]
    }
  },

  'violin-bowing': {
    id: 'violin-bowing',
    category: 'violin',
    name: 'Improve my bow control',
    description: 'Make those challenging bow techniques feel more natural.',
    examples: [
      'Get that light, bouncy sound in fast passages.',
      'Smooth out your string crossings.'
    ],
    augmentation: {
      type: 'exploration',
      userRole: "Share what's giving you trouble.",
      aiRole: "I'll suggest specific ways to practice it.",
      limitations: [
        "I can't demonstrate techniques.",
        "I can't hear your sound.",
        'Your teacher knows best.'
      ]
    }
  },

  'violin-interpretation': {
    id: 'violin-interpretation',
    category: 'violin',
    name: 'Understand my piece better',
    description: 'Learn how the great violinists approached your music.',
    examples: [
      'Discover how Bach was really played.',
      'Learn about different playing styles through history.'
    ],
    augmentation: {
      type: 'exploration',
      userRole: "Tell me what piece you're working on.",
      aiRole: "I'll share interesting historical insights.",
      limitations: [
        'The final musical choices are yours.',
        "I can't judge your interpretation.",
        'Your artistic vision comes first.'
      ]
    }
  },

  'violin-practice-planning': {
    id: 'violin-practice-planning',
    category: 'violin',
    name: 'Make my practice time count',
    description: 'Get more done in your practice sessions.',
    examples: [
      'Create a practice plan that fits your schedule.',
      'Focus on what really needs work.'
    ],
    augmentation: {
      type: 'refinement',
      userRole: 'Share your practice goals.',
      aiRole: "I'll help you make a realistic plan.",
      limitations: [
        'You know your energy levels best.',
        "Everyone's stamina is different.",
        'You decide when to practice.'
      ]
    }
  }
};

// Helper functions
export function getViolinTask(taskId: keyof typeof VIOLIN_TASKS): Task {
  const task = VIOLIN_TASKS[taskId];
  if (!task) {
    throw new Error(`Violin task "${taskId}" not found.`);
  }
  return task;
}

export function getAllViolinTasks(): Task[] {
  return Object.values(VIOLIN_TASKS);
}
