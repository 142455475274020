import { useLocation, useNavigate } from 'react-router-dom';
import { cn } from '@/lib/utils';

const NAV_ITEMS = [
  {
    name: 'Assist',
    path: '/',
    // Also match /assist route
    isActive: (pathname: string) => pathname === '/' || pathname === '/assist'
  },
  {
    name: 'Build',
    path: '/build',
    isActive: (pathname: string) => pathname === '/build'
  },
  {
    name: 'Learn',
    path: '/learn',
    isActive: (pathname: string) => pathname === '/learn'
  }
];

export function Footer() {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <footer className={cn(
      'fixed bottom-0 left-0 right-0 z-50',
      'bg-footer',  // Use new utility class
      'border-t border-footer',  // Use new utility class
      'px-4 py-2 md:py-3'
    )}>
      <nav className={cn(
        'max-w-[61.8ch] mx-auto',
        'flex items-center justify-around',
      )}>
        {NAV_ITEMS.map(item => {
          const isActive = item.isActive(location.pathname);

          return (
            <button
              key={item.path}
              onClick={() => navigate(item.path)}
              className={cn(
                'relative px-4 py-2',
                'font-mono text-sm font-medium',
                'hover:text-footer-nav-hover', // Use new utility class
                isActive ? 'text-footer-nav-active' : 'text-footer-nav', // Use new utility classes
              )}
            >
              {/* Active Indicator */}
              {isActive && (
                <span className={cn(
                  'absolute -bottom-2 left-4 right-4 h-0.5',
                  'bg-footer-nav-active', // Use new utility class
                )} />
              )}
              {item.name}
            </button>
          );
        })}
      </nav>
    </footer>
  );
}