import { Terminal } from 'lucide-react';
import { cn } from '@/lib/utils';
import { ThemeToggle } from '@/components/theme-toggle';

interface HeaderProps {
  className?: string;
}

export function Header({ className }: HeaderProps) {
  return (
    <header
      className={cn(
        'fixed top-0 left-0 right-0 z-40',
        'bg-[rgb(var(--background))]',
        'border-b border-default',
        'px-4 py-3',
        className
      )}
    >
      <div
        className={cn(
          'max-w-[61.8ch] mx-auto',
          'flex items-center justify-between'
        )}
      >
        {/* Logo and Title */}
        <div className="flex items-center gap-3">
          <Terminal className="w-5 h-5" />
          <h1 className="font-mono text-base font-bold">guide</h1>
        </div>

        {/* Right Section */}
        <div className="flex items-center gap-4">
          <a
            href="https://thinkingmachines.ai/"
            target="_blank"
            rel="noopener noreferrer"
            className={cn(
              'px-3 py-1.5',
              'font-mono text-sm text-white',
              'bg-[#3082ae]',
              'hover:bg-[#2a6996]',
              'rounded-lg',
              'transition-colors duration-200',
              'focus:outline-none focus:ring-2',
              'focus:ring-[#3082ae]/50'
)}
          >
            Go to Rainer
          </a>
          <ThemeToggle />
        </div>
      </div>
    </header>
  );
}
