import { useTheme } from './theme-provider';
import { Sun, Moon } from 'lucide-react';
import { cn } from '@/lib/utils';

interface ThemeToggleProps {
  className?: string;
}

export function ThemeToggle({ className }: ThemeToggleProps) {
  const { theme, toggleTheme } = useTheme();

  return (
    <button
      onClick={toggleTheme}
      className={cn(
        // Ensure the button has the same padding as the link and uses flex for centering
        'flex items-center justify-center px-3 py-1.5',
        className
      )}
      title={`Switch to ${theme === 'light' ? 'dark' : 'light'} mode`}
      aria-label={`Switch to ${theme === 'light' ? 'dark' : 'light'} mode`}
    >
      {/* Increase the icon container to w-5 h-5 to match the logo size */}
      <div className="relative flex items-center justify-center w-5 h-5">
        <Sun
          className={cn(
            'absolute inset-0 transition-all duration-500 origin-center',
            theme === 'light'
              ? 'rotate-0 opacity-0 scale-50'
              : 'rotate-90 opacity-100 scale-100'
          )}
        />
        <Moon
          className={cn(
            'absolute inset-0 transition-all duration-500 origin-center',
            theme === 'light'
              ? 'rotate-0 opacity-100 scale-100'
              : '-rotate-90 opacity-0 scale-50'
          )}
        />
      </div>
    </button>
  );
}