// faq-data.ts
interface Choice {
  text: string;
  consequence: string;  // Feedback to the user.
  next?: string;         // ID of the next node.  Optional.
  action?: string;     // Special actions, like "GO_TO_CLAUDE"
}

interface TMLNode {  // Renamed interface
  id: string;
  type: 'intro' | 'info' | 'question' | 'task' | 'success' | 'explanation';
  content: string;
  choices?: Choice[];
}

const adventure: Record<string, TMLNode> = { // Strongly typed
  'start': {
      id: 'start',
      type: 'intro',
      content: "Welcome, dear friend, to the Thinking Machines Lab (TML) adventure!  We're thrilled to have you.  Are you ready to explore the world of AI and discover what TML is all about?",
      choices: [
          { text: "Yes, let's embark on this journey!", consequence: "Excellent!  First, let's get a sense of what TML *is*.", next: 'what_is_tml' },
          { text: "I'm curious, but what *is* AI, fundamentally?", consequence: "A wise question! Let's take a quick detour to define AI.", next: 'what_is_ai' }
      ]
  },
  'what_is_ai': {
      id: 'what_is_ai',
      type: 'info',
      content: "Artificial Intelligence (AI) is the ability of a computer or a robot controlled by a computer to do tasks that are usually done by humans because they require human intelligence and discernment.  Think of it as teaching machines to think and learn, to solve problems and make decisions.  It's a vast and exciting field!",
      choices: [
          { text: "Okay, that makes sense. Now, what is TML?", consequence: "Perfect! Let's dive into the heart of TML.", next: 'what_is_tml' },
          { text: "Can you give me a simple example of AI?", consequence: "Of course! Let's illustrate with a concrete example.", next: 'ai_example' }
      ]
  },
  'ai_example': {
      id: 'ai_example',
      type: 'info',
      content: "Imagine a spam filter in your email.  It learns to identify and filter out unwanted emails based on patterns it recognizes (like certain words or phrases).  That's a simple form of AI at work, making your life a little easier!",
      choices: [
          { text: "I understand now.  Tell me about TML.", consequence: "Wonderful! Let's proceed to the core of our adventure.", next: 'what_is_tml' }
      ]
  },
    'what_is_tml': {
        id: 'what_is_tml',
        type: 'info',
        content: "TML AI is an artificial intelligence research and product company. We are scientists, engineers, and builders who've created some of the most widely used AI products. Our mission is to build a future where everyone has access to the knowledge and tools to make AI work for their unique needs and goals.",
        choices: [
            { text: "What kind of products does TML build?", consequence: "Let's explore the exciting products TML is working on.", next: 'tml_products' },
            { text: "What is TML's approach to research?", consequence: "Let's delve into TML's research philosophy.", next: 'tml_research' },
            { text: "Who are the people behind TML?", consequence: "Meet the team (briefly)!", next: 'tml_team' }
        ]
    },
    'tml_products': {
        id: 'tml_products',
        type: 'info',
        content: "TML focuses on creating AI systems that are:\n\n*   **Collaborative:**  Working *with* people, not replacing them.\n*   **Adaptable:**  Tailored to specific needs and values, not one-size-fits-all.\n*   **Multimodal:**  Understanding and interacting with various types of data (text, images, etc.).\n*   **Frontier Capabilities** Built on foundational, advanced models. \n\nWe're building AI that can adapt to the full spectrum of human expertise and enable a broader spectrum of applications, beyond just programming and mathematics.",
        choices: [
            { text: "Tell me more about this 'human-AI collaboration'.", consequence: "Let's explore this key concept.", next: 'human_ai_collaboration' },
            { text: "What does 'multimodal' mean in this context?", consequence: "Let's clarify this important term.", next: 'multimodal_explained' },
             { text: "How does TML ensure these products are useful?", consequence: "Great question! Let's talk about our approach.", next: 'tml_approach' }
        ]
    },
    'human_ai_collaboration': {
        id: 'human_ai_collaboration',
        type: 'info',
        content: "Instead of building AI that works completely independently, TML focuses on AI that partners with humans.  Think of it as a powerful assistant that augments your abilities, providing insights, generating ideas, and handling tedious tasks, allowing you to focus on the creative and strategic aspects.",
        choices: [
            { text: "That sounds promising!  What about 'multimodal'?", consequence: "Let's define that next.", next: 'multimodal_explained' },
            { text: "Go back to the overview of TML's products.", consequence: "Certainly! Let's review.", next: 'tml_products' }
        ]
    },
    'multimodal_explained': {
        id: 'multimodal_explained',
        type: 'info',
        content: "Multimodal means that the AI can understand and process different *types* of information, not just text.  This includes images, audio, video, and potentially even sensor data in the future.  This allows for more natural and intuitive interaction, and opens up a wider range of applications.",
        choices: [
            { text: "So, it's more than just a text-based AI?", consequence: "Exactly!  It's about richer, more comprehensive interaction.", next: 'tml_products_recap' },
             { text: "Tell me about the importance of infrastructure at TML", consequence: "Let's do that.", next: 'infrastructure_explained' },

        ]
    },
    'tml_products_recap': {
        id: 'tml_products_recap',
        type: 'info',
        content: "Precisely! TML is building AI that goes beyond text, incorporating multiple modes of input and output for a more powerful and versatile experience.",
        choices: [
            { text: "Let's move on to TML's research approach.", consequence: "Excellent! Let's explore how TML contributes to the AI field.", next: 'tml_research' }
        ]
    },
'infrastructure_explained': {
    id: 'infrastructure_explained',
    type: 'info',
    content: "At TML, we believe that solid foundations are critical.  We prioritize building robust, reliable, and efficient infrastructure to support our research and product development. This means:\n\n*   **Reliability:**  Systems that work consistently and predictably.\n*   **Efficiency:**  Maximizing the use of resources (like computing power).\n*   **Ease of Use:**  Making it easy for our researchers and engineers to work.\n*   **Security:**  Protecting our data and systems.\n\nWe aim to build things correctly for the long haul, maximizing both productivity and security.",
    choices: [
        { text: "How does this focus on infrastructure benefit the research?", consequence: "Good question! Let's connect the dots.", next: 'infrastructure_benefits' },
        { text: "Return to the overview of multimodal AI.", consequence: "Certainly, let's revisit that.", next: 'multimodal_explained' }
    ]
},
'infrastructure_benefits': {
    id: 'infrastructure_benefits',
    type: 'info',
    content: "By investing in high-quality infrastructure, TML enables its researchers to:\n\n*   **Focus on Innovation:**  Spend less time troubleshooting and more time on groundbreaking work.\n*   **Iterate Quickly:**  Run experiments and test ideas rapidly.\n*   **Scale Effectively:**  Handle large datasets and complex models.\n*   **Collaborate Seamlessly:**  Share resources and work together efficiently.\n\nUltimately, this leads to faster progress and more impactful results.",
    choices: [
        { text: "That makes sense. Let's talk about TML's research.", consequence: "Perfect! Let's explore TML's research philosophy.", next: 'tml_research' }
    ]
},
    'tml_research': {
        id: 'tml_research',
        type: 'info',
        content: "TML believes that scientific progress is a collective effort.  We're committed to:\n\n*   **Openness:** Sharing our work with the wider community (publications, blog posts, code).\n*   **Collaboration:**  Working with other researchers and builders.\n*   **Empirical Approach:**  Combining proactive research with real-world testing for AI safety.\n*   **Measuring Value:**  Focusing on how our systems create genuine value, not just optimizing metrics.\n\nWe believe that sharing our work will not only benefit the public, but also improve our own research culture.",
        choices: [
            { text: "How does TML approach AI safety?", consequence: "Let's examine this crucial aspect.", next: 'tml_safety' },
            { text: "Tell me about the 'learning by doing' approach.", consequence: "Let's explore how research and product development intertwine.", next: 'learning_by_doing' },
            { text: "Who are the people involved in this research?", consequence: "Let's briefly meet the team.", next: 'tml_team' }
        ]
    },
    'tml_safety': {
        id: 'tml_safety',
        type: 'info',
        content: "TML takes a practical and iterative approach to AI safety:\n\n*   **High Safety Bar:**  Preventing misuse of released models while maximizing user freedom.\n*   **Sharing Best Practices:**  Sharing safety recipes and guidelines with the industry.\n*   **Accelerating Research:**  Sharing code, datasets, and model specs to promote external research on alignment.\n* **Real World Testing** Testing on real systems provides safety insights for more capable systems in future.\n\nWe believe that methods developed for present-day systems provide valuable insights for the future.",
        choices: [
            { text: "This sounds like a responsible approach.  Tell me about 'learning by doing'.", consequence: "Let's explore the synergy between research and products.", next: 'learning_by_doing' },
            { text: "Go back to the overview of TML's research.", consequence: "Certainly!  Let's recap.", next: 'tml_research' }
        ]
    },
    'learning_by_doing': {
        id: 'learning_by_doing',
        type: 'info',
        content: "TML believes in the power of *co-designing* research and products.  This means:\n\n*   **Products Inform Research:**  Real-world use of our products guides us to solve the most impactful problems.\n*   **Research Enhances Products:**  Cutting-edge research leads to better, more capable products.\n*   **Iterative Learning:**  Deployment of products allows for continuous learning and improvement.\n\nIt's a continuous feedback loop that drives innovation.",
        choices: [
            { text: "So, research and product development are closely linked?", consequence: "Exactly!  They strengthen and inform each other.", next: 'learning_by_doing_recap' },
            { text: "Let's talk about the people behind TML.", consequence: "A great idea!  Let's meet the team (briefly).", next: 'tml_team' }
        ]
    },
    'learning_by_doing_recap': {
        id: 'learning_by_doing_recap',
        type: 'info',
        content: "Precisely! This close integration of research and product development allows TML to stay grounded in reality, solve real-world problems, and continuously improve its AI systems.",
        choices: [
            { text: "Let's move on to the TML team.", consequence: "Wonderful!  Let's meet the people making this happen.", next: 'tml_team' }
        ]
    },
    'tml_team': {
        id: 'tml_team',
        type: 'info',
        content: "TML is built by a team of experienced scientists, engineers, and builders.  They've contributed to some of the most widely used AI products and projects, including:\n\n*   ChatGPT\n*   Character.ai\n*   Mistral\n*   PyTorch\n*   OpenAI Gym\n*   Fairseq\n*   Segment Anything\n\nIt's a team with a proven track record of innovation and impact in the AI field.",
        choices: [
            { text: "That's an impressive list!  How can I learn more or get involved?", consequence: "Excellent question! Let's explore your options.", next: 'get_involved' },
             { text: "I'm interested in working with TML", consequence: "That's fantastic!  Let's talk about job opportunities.", next: 'job_opportunities' }
        ]
    },
'job_opportunities': {
  id: 'job_opportunities',
  type: 'info',
  content: "TML is looking for talented individuals to join the team!  Here are some key roles:\n\n*   **Product Builders:**  People with a track record of building successful AI-driven products.\n*   **Machine Learning Experts:**  Scientists and engineers with demonstrable achievements in ML research and engineering.\n*   **Research Program Manager:**  Individuals who excel at problem-solving, fast learning, and driving operational excellence.\n\nYou can find more details and submit applications on the TML website.",
  choices: [
    { text: "Take me to the job application page.", consequence: "Redirecting you now...", action: 'GO_TO_JOBS' },
    { text: "Let's go back to how I can get involved in general.", consequence: "Of course!", next: 'get_involved' }
  ]
},
    'get_involved': {
        id: 'get_involved',
        type: 'info',
        content: "There are several ways to connect with TML and learn more:\n\n*   **Follow us on X (Twitter):**  @thinkymachines\n*   **Explore Job Opportunities:**  If you're interested in working with us, check out the careers page.\n* **Head to Rainer** To start using a collaborative and helpful AI tool.\n\nWe're excited to have you join the TML community!",
        choices: [
            { text: "Take me to Rainer!", consequence: "Wonderful!  Let's get you started with a powerful AI tool.", action: 'GO_TO_CLAUDE' },
            { text: "Show me the job opportunities.", consequence: "Let's explore career options at TML.", next: 'job_opportunities' }
        ]
    },
      'GO_TO_CLAUDE': {
        id: 'GO_TO_CLAUDE',
        type: 'success',
        content: "You've reached the end of the TML adventure! You're now well-equipped to understand TML's mission, approach, and the exciting world of AI. We encourage you to explore Rainer and experience the power of collaborative AI firsthand.",
        choices: [] // No choices here, as it's the end. The action will be handled by the component.
    },
     'GO_TO_JOBS': {
        id: 'GO_TO_JOBS',
        type: 'success',
        content: "You've completed the TML adventure and expressed interest in joining our team! That's fantastic. We encourage you to explore the open positions and submit an application if you find a role that aligns with your skills and passion.",
        choices: [] // No choices - this is an end state
    }
};

export { adventure, type TMLNode, type Choice };