import React from 'react'
import type { JSX } from 'react'
import { TerminalText } from '@/components/ui/terminal-text'
import { ChoiceButton } from '@/components/ui/choice-button'
import { BuildResponse } from './BuildResponse'
import { cn } from '@/lib/utils'
import CodeViewer from '@/components/ui/code-viewer'
import { Loading } from '@/components/loading'
import { generateText } from 'ai'
import { createAnthropic } from '@ai-sdk/anthropic'
import { getMainCodingPrompt } from '@/lib/build-prompts'

if (!import.meta.env.VITE_ANTHROPIC_API_KEY) {
  throw new Error('VITE_ANTHROPIC_API_KEY environment variable is required');
}

const anthropic = createAnthropic({
  apiKey: import.meta.env.VITE_ANTHROPIC_API_KEY,
  headers: { 'anthropic-dangerous-direct-browser-access': 'true' }
})

const MAX_STEPS = 2

interface AppChoice {
  id: 'violinist' | 'cashier' | 'social-worker'
  label: string
  description: string
  subtitle: string
  usage: string
  variants: {
    question: string
    choices: Array<{
      id: string
      usage: string
      label: string
      description: string
      heading: string
      outcome: string
    }>
  }
}

const APP_CHOICES: AppChoice[] = [
  {
    id: 'violinist',
    label: 'Tempo Ladder',
    subtitle: 'Perfect practice speed progression calculator',
    description: 'A disposable practice tempo calculator that creates scientifically-spaced speed progressions. Input your target tempo and available minutes, receive exact practice speeds and precise change moments. The app should feature large, high-contrast displays optimized for peripheral vision during practice, with clear visual/audio signals for tempo changes. Consider integrated metronome visualization. Focus on zero cognitive load during use - all decisions made upfront. Essential principle: musician should never have to think about "when do I speed up?" during practice.',
    usage: 'Calculate perfect practice tempos for your current session',
    variants: {
      question: 'What kind of pieces are you practicing right now?',
      choices: [
        {
        id: 'orchestra',
        heading: "Orchestra Section",
        outcome: "Coordinate tempo changes across your section",
        label: 'Section Practice Mode',
        usage: 'Coordinated tempo progression for orchestral sections',
        description: 'Adapts the base calculator for group practice scenarios, accounting for section size in progression timing. Includes unified starting signals and longer stabilization periods between tempo changes.'
      },
      {
        id: 'early-music',
        heading: "Early Music",
        outcome: "Match historical speeds and ornaments perfectly",
        label: 'Period Performance Mode',
        usage: 'Historical tempo relationships and adjustments',
        description: 'Incorporates period-specific tempo relationships and common baroque/classical adjustments into the progression ladder. Includes historical tempo term references.'
      },
      {
        id: 'contemporary',
        heading: "Contemporary",
        outcome: "Master complex modern time signatures fast",
        label: 'Complex Meter Mode',
        usage: 'Practice progression for irregular time signatures',
        description: 'Specialized for modern compositions with compound meters, showing subdivision-based practice speeds and rhythmic relationship markers.'
      }
    ]
  }
},
{
    id: 'cashier',
    label: 'Split Calculator',
    subtitle: 'Instant payment splitting with tips',
    description: 'A zero-memory bill splitting calculator optimized for fast-paced service environments. Input total amount and split count, receive instant per-person amounts with tip options. Design should use large, clear typography with high contrast for easy reading in varied lighting. Include quick-access buttons for common scenarios. Focus on single-glance information retrieval - all key information visible without scrolling or clicking.',
    usage: 'Calculate exact split amounts for group payments',
    variants: {
      question: 'What types of splits do you handle most?',
      choices: [
        {
        id: 'fine-dining',
        heading: "Fine Dining",
        outcome: "Split wine and high-end meals easily",
        label: 'Fine Dining Mode',
        usage: 'Handle wine splits and premium tip tiers',
        description: 'Enhanced with wine bill splitting options and upscale tip percentages (18/20/22%). Includes separate beverage and food split capabilities.'
      },
      {
        id: 'cafe',
        heading: "Café Service",
        outcome: "Handle quick cash splits instantly",
        label: 'Quick Service Mode',
        usage: 'Fast splits with cash rounding',
        description: 'Optimized for counter service with quick even/uneven toggle and smart rounding for cash transactions. Includes quick-split presets.'
      },
      {
        id: 'bar',
        heading: "Bar Service",
        outcome: "Manage running tabs between groups",
        label: 'Bar Tab Mode',
        usage: 'Running tab splits with drink averaging',
        description: 'Specialized for bar environments with running tab split calculations and drink price averaging features. Includes quick tab transfer options.'
      }
    ]
  }
},
{
    id: 'social-worker',
    label: 'Interval Timer',
    subtitle: 'Billing period tracker with alerts',
    description: 'A session timing calculator focused on billing compliance. Input start time and billing increment requirements, receive clear visual blocks with approach warnings. Design should emphasize peripheral awareness - information visible without direct focus. Use color coding for time block status. Focus on minimal interaction during active client sessions.',
    usage: 'Track billing intervals during client sessions',
    variants: {
      question: 'How do your sessions typically run?',
      choices: [
        {
        id: 'home-visit',
        heading: "Home Visits",
        outcome: "Track travel and session time together",
        label: 'Home Visit Mode',
        usage: 'Track travel and session time blocks',
        description: 'Adapted for mobile service delivery with integrated travel time tracking and mileage interval markers. Includes location-based session boundaries.'
      },
      {
        id: 'group',
        heading: "Group Sessions",
        outcome: "Balance time between multiple clients",
        label: 'Group Session Mode',
        usage: 'Monitor per-client time in group settings',
        description: 'Specialized for group sessions with per-person time tracking and group billing interval compliance. Includes participant time balancing.'
      },
      {
        id: 'crisis',
        heading: "Crisis Response",
        outcome: "Monitor critical intervention timings precisely",
        label: 'Crisis Response Mode',
        usage: 'Time-critical intervention tracking',
        description: 'Optimized for crisis response with protocol-specific time blocks and handoff interval tracking. Includes urgent response timing guides.'
      }
    ]
  }
}
]

function BuildHeader({ step, appType }: { step: number; appType: AppChoice | null }): JSX.Element {
  return (
    <div className={cn(
      'font-mono text-sm bg-task-header p-4 rounded-lg space-y-4'
    )}>
      <div className="space-y-2">
        <div className="font-bold">Build Your App</div>
        <div className="text-subtitle">
          {appType ? appType.subtitle : 'Create a custom app with AI assistance'}
        </div>
      </div>
      <div className="relative h-6 mt-3" style={{ maxWidth: '16rem' }}>
        <div className="absolute inset-0 bg-progress-container rounded-lg" />
        <div
          className="absolute inset-y-0 left-0 bg-progress-fill transition-all duration-500 rounded-lg"
          style={{ width: `${Math.round((step / MAX_STEPS) * 100)}%` }}
        />
        <div className="absolute inset-0 flex items-center justify-center">
          <span className="font-mono text-xs font-medium text-progress-label">
            {step} / {MAX_STEPS}
          </span>
        </div>
      </div>
    </div>
  )
}

function ChoiceArea({ choices, onSelect }: { 
  choices: Array<{ id: string; heading: string; outcome: string }>;
  onSelect: (id: string) => void;
}): JSX.Element {
  return (
    <div className="space-y-2">
      {choices.map((choice, index) => (
        <ChoiceButton
          key={choice.id}
          indicator={String.fromCharCode(65 + index)}
          onClick={() => onSelect(choice.id)}
        >
          <div className="space-y-2">
            <div className="font-medium">{choice.heading}</div>
            <div className="text-sm text-subtitle">
              {choice.outcome}
            </div>
          </div>
        </ChoiceButton>
      ))}
    </div>
  )
}

interface CodeVersion {
  code: string
  timestamp: number
}

export default function BuildContent({ className }: { className?: string }): JSX.Element {
  const [step, setStep] = React.useState(1)
  const [appType, setAppType] = React.useState<AppChoice | null>(null)
  const [customization, setCustomization] = React.useState<string | null>(null)
  const [response, setResponse] = React.useState<string>('')
  const [codeVersions, setCodeVersions] = React.useState<CodeVersion[]>([])
  const [isViewerShowing, setIsViewerShowing] = React.useState(false)
  const [isGenerating, setIsGenerating] = React.useState(false)
  const [aiResponseText, setAiResponseText] = React.useState<string>('')

  const handleAppSelect = (id: string) => {
    const selected = APP_CHOICES.find(choice => choice.id === id)
    if (selected) {
      setAppType(selected)
      setStep(2)
      setResponse(selected.variants.question)
    }
  }

  const handleCustomizationSelect = async (id: string) => {
    if (!appType) return

    try {
      setCustomization(id)
      setIsGenerating(true)
      setAiResponseText('')

      // Find the selected variant
      const variant = appType.variants.choices.find(choice => choice.id === id)
      if (!variant) {
        throw new Error('Selected variant not found')
      }

      // Generate code
      const { text } = await generateText({
        model: anthropic('claude-3-5-sonnet-20241022'),
        messages: [
          {
            role: 'system',
            content: getMainCodingPrompt()
          },
          {
            role: 'user',
            content: `Create a ${appType.description} single page app for a friend you care deeply about who is a ${id} and who needs ${appType.usage}. They specifically need to ${variant.outcome}, so the app should be optimized for ${variant.usage}.

You care deeply about exacting craftsmanship and beauty, and this is how you show love to those that care for you. Your intuition is that more of a product's success than one would think is downstream of the fact that people like beautiful things—and for kind of rational reasons because what does a beautiful thing tell you? Well it tells you the person who made it really cared… And so if you care about the infrastructure being holistically good, indexing on the superficial characteristics that you can actually observe is not an irrational thing to do.

The app is called ${appType.label}. Key implementation considerations:
${variant.description}

The app should be fully self-contained and able to run directly in a React tsx Codesandbox. Don't refer to this prompt in your output. <strong>**YOU MUST ALWAYS**</strong> include a brief explanation about the app to your nontechnical friend after the code block, focused on how it helps them ${variant.outcome} through ${variant.usage}.`
          },
          {role: 'assistant', content: "```typescript"}
        ]
      })

      // Split response at backticks - code is before, text is after
      const [code, explanation] = text.split('```')
      if (!code || !explanation) {
        throw new Error('Incomplete code response')
      }
      
      // Update state after successful generation
      setCodeVersions([{ code: code.trim(), timestamp: Date.now() }])
      setAiResponseText(explanation.trim())
      setStep(2) // Keep at 2 since MAX_STEPS is 2
      setIsViewerShowing(true)
    } catch (err) {
      console.error('Failed to generate code:', err)
    } finally {
      setIsGenerating(false)
    }
  }

  const handleCodeUpdate = (newCode: string) => {
    setCodeVersions(prev => [...prev, { code: newCode, timestamp: Date.now() }])
  }

  const latestCode = codeVersions[codeVersions.length - 1]?.code ?? ''

  return (
    <div className={cn('space-y-6', className)}>
      <BuildHeader step={step} appType={appType} />
      
      <div className="space-y-6">
        {isGenerating ? (
          <div className="text-lg">
            <Loading type="build" className="text-left" />
          </div>
        ) : (
          <>
            {step <= 2 && response && (!codeVersions.length || !customization) && (
              <TerminalText
                content={response}
                className="text-lg"
                speed="normal"
                showCursor={true}
              />
            )}
            <div className="space-y-2">
              {step === 1 && (
                <ChoiceArea
                  choices={APP_CHOICES.map(({ id, label, usage }) => ({
                    id,
                    heading: label,
                    outcome: usage
                  }))}
                  onSelect={handleAppSelect}
                />
              )}
              {step === 2 && appType && !codeVersions.length && (
                <ChoiceArea
                  choices={appType.variants.choices}
                  onSelect={handleCustomizationSelect}
                />
              )}
              {step === 2 && appType && customization && codeVersions.length > 0 && (
                <>
                  <BuildResponse
                    appName={appType.label}
                    code={latestCode}
                    onShowCode={(code) => {
                      handleCodeUpdate(code)
                      setIsViewerShowing(true)
                    }}
                  />
                  {aiResponseText && (
                    <TerminalText
                      content={aiResponseText}
                      className="text-lg"
                    />
                  )}
                </>
              )}
            </div>
          </>
        )}
      </div>

      <CodeViewer
        code={latestCode}
        language="tsx"
        isShowing={isViewerShowing}
        onClose={() => setIsViewerShowing(false)}
      />
    </div>
  )
}