import React from 'react';
import { useStore } from '@/lib/store';
import { cn } from '@/lib/utils';
import { type Message } from '@/lib/types';

interface ConversationHistoryProps {
  className?: string;
}

export function ConversationHistory({ className }: ConversationHistoryProps) {
  const messages = useStore(state => state.messages);
  const conversationCollapsed = useStore(state => state.conversationCollapsed);
  const toggleConversationCollapse = useStore(state => state.toggleConversationCollapse);
  const currentTask = useStore(state => state.currentTask);
  const error = useStore(state => state.error);

  // Filter messages to only show those related to the current task
  const taskMessages = React.useMemo(() => {
    if (!currentTask) return messages;
    return messages.filter(msg => msg.metadata?.taskId === currentTask);
  }, [messages, currentTask]);

  // Early returns for conditions where we don't want to show anything
  if (error || taskMessages.length === 0) {
    return null;
  }

  const MessageContent = ({ message }: { message: Message }) => {
    const isUser = message.role === 'user';

    return (
      <div
        className={cn(
          'px-4 py-3 rounded',
          isUser ? 'bg-user-message' : 'bg-transparent' // Use new utility class
        )}
      >
        {/* Message Header */}
        <div className="text-xs text-message-header mb-2">
          {isUser ? 'You' : 'AI'}
          {message.metadata?.type && (
            <span className="ml-2 opacity-50">
              ({message.metadata.type})
            </span>
          )}
        </div>

        {/* Message Content */}
        <div className="whitespace-pre-wrap text-sm">
          {message.content}
        </div>
      </div>
    );
  };

  return (
    <div className={cn('relative', className)}>
      {/* Collapse Toggle */}
      <button
        onClick={() => toggleConversationCollapse()}
        className={cn(
          'absolute -top-3 right-4 px-3 py-1 rounded-full',
          'text-xs font-mono',
          'bg-history-button hover:bg-history-button-hover',
          'transition-colors duration-200'
        )}
      >
        {conversationCollapsed ? 'Show History' : 'Hide History'}
      </button>

      {/* Messages */}
      {!conversationCollapsed && (
        <div className="space-y-4 mt-4 max-h-[50vh] overflow-y-auto">
          {taskMessages.map((message, index) => (
            <MessageContent key={message.id || index} message={message} />
          ))}
        </div>
      )}
    </div>
  );
}