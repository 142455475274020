// use-choice-navigation.ts
import { useEffect } from 'react';
import type { Choice } from '@/lib/faq-data';  // Correctly import Choice

export function useChoiceNavigation(
  choices: Choice[] | undefined,
  onSelect: (nextId: string | undefined, action?: string) => void // Accept action
) {
  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      // Get the pressed key in uppercase
      const key = e.key.toUpperCase();

      // Check if the key corresponds to a choice (A-Z)
      const index = key.charCodeAt(0) - 65;

      // Correctly handle undefined choices and access .next
      if (choices && index >= 0 && index < choices.length) {
        const choice = choices[index];
         if (choice) { // Check if the choice is valid.
              onSelect(choice.next, choice.action); // Pass both nextId and action
        }
      }
    };

    window.addEventListener('keypress', handleKeyPress);
    return () => window.removeEventListener('keypress', handleKeyPress);
  }, [choices, onSelect]); //  Dependencies are correct.
}