// LearnPage.tsx
import { useState, useEffect } from 'react';
import { TerminalText } from '@/components/ui/terminal-text';
import { ChoiceButton } from '@/components/ui/choice-button';
import { adventure, type TMLNode } from '@/lib/faq-data'; // Updated import
import { useChoiceNavigation } from '@/hooks/use-choice-navigation';
import { cn } from '@/lib/utils';

export default function LearnPage() {
    const [currentNode, setCurrentNode] = useState<TMLNode>(adventure.start!);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [activeKey, setActiveKey] = useState<string | null>(null);
    const [visitedNodes, setVisitedNodes] = useState<Set<string>>(new Set(['start']));
    const [lastChoice, setLastChoice] = useState<string>('');

    const handleChoice = async (nextId: string | undefined, choiceText: string, action?: string) => {
        if (!nextId && !action) {
            return; // Handle unexpected end.
        }

        setIsTransitioning(true);
        setLastChoice(choiceText);
        await new Promise(resolve => setTimeout(resolve, 200));

        if (action === 'GO_TO_CLAUDE') {
            window.location.href = "https://thinkingmachines.ai/"; // Redirect
            return;
        }
          if (action === 'GO_TO_JOBS') {
            window.location.href = "https://jobs.lever.co/thinkymachines"; // Replace with actual URL
            return;
        }

        if (nextId) { // Only proceed if nextId is valid
            setVisitedNodes(prev => new Set([...prev, nextId]));
            const nextNode = adventure[nextId];
            if (!nextNode) {
                console.error(`Node not found: ${nextId}`);
                return;
            }
            setCurrentNode(nextNode);
        }
        setIsTransitioning(false);
    };

    useChoiceNavigation(currentNode.choices, (nextId) => {
        const choice = currentNode.choices?.find(c => c.next === nextId);
        handleChoice(nextId, choice?.text || '', choice?.action);
    });

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            const key = e.key.toUpperCase();
            if (key.match(/^[A-Z]$/)) {
                setActiveKey(key);
            }
        };

        const handleKeyUp = () => {
            setActiveKey(null);
        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, []);

    return (
        <div className="space-y-8">
            {/* Header - Updated text */}
            <div className="font-mono text-sm text-header-learn flex items-center justify-between">
                <span>Thinking Machines Lab Adventure</span>
                <span>{visitedNodes.size} topic(s) explored</span>
            </div>

            {/* Content Area */}
            <div
                className={cn(
                    'space-y-6',
                    'transition-opacity duration-200',
                    isTransitioning ? 'opacity-0' : 'opacity-100'
                )}
            >
                {/* Navigation History */}
                {lastChoice && (
                    <div className="font-mono text-xs text-nav-history">
                        Last selected: {lastChoice}
                    </div>
                )}

                {/* Main Content */}
                <TerminalText
                    content={currentNode.content}
                    className="text-lg"
                    speed="fast"
                />

                {/* Choices */}
                {currentNode.choices && (
                    <div className="space-y-2">
                        {currentNode.choices.map((choice, index) => {
                            const indicator = String.fromCharCode(65 + index);
                            const isActive = activeKey === indicator;
                            const isVisited = choice.next ? visitedNodes.has(choice.next) : false;

                            return (
                                <ChoiceButton
                                    key={choice.text}
                                    indicator={indicator}
                                    onClick={() => handleChoice(choice.next, choice.text, choice.action)} // Pass action
                                    className={cn(
                                        'transition-colors duration-100',
                                        isActive && 'bg-black/10 dark:bg-white/10',
                                        isVisited && 'border-l-2 border-black/10 dark:border-white/10'
                                    )}
                                >
                                    <div className="space-y-1">
                                        <div className="font-medium">
                                            {choice.text}
                                            {isVisited && (
                                                <span className="ml-2 text-xs text-subtitle">
                                                    (visited)
                                                </span>
                                            )}
                                        </div>
                                        {choice.consequence && (
                                            <div className="text-sm text-subheading">
                                                {choice.consequence}
                                            </div>
                                        )}
                                    </div>
                                </ChoiceButton>
                            );
                        })}
                    </div>
                )}
            </div>

             {/* Help Text */}
            <div className="flex justify-between items-center text-xs font-mono text-help">
                <span>Use keyboard or click to choose</span>
                {currentNode.choices && currentNode.choices.length > 0 && (
                    <span className="font-mono">
                        {currentNode.choices.map((_, i) => String.fromCharCode(65 + i)).join(' ')}
                    </span>
                )}
            </div>
        </div>
    );
}