"use client"

import React from "react"
import { useMediaQuery } from "../../hooks/use-media-query"
import { Sheet, SheetContent } from "./sheet"
import { cn } from "../../lib/utils"

interface CodeViewerUIProps {
  children: React.ReactNode | React.ReactNode[]
  isShowing: boolean
  onClose: () => void
}

const MobileView = React.memo(function MobileView({
  children,
  isShowing,
  onClose,
}: CodeViewerUIProps) {
  return (
    <Sheet open={isShowing} onOpenChange={onClose}>
      <SheetContent side="bottom" className="h-[80vh] p-0">
        <div className="flex h-full flex-col overflow-y-auto">
          {children}
        </div>
      </SheetContent>
    </Sheet>
  )
})

const DesktopView = React.memo(function DesktopView({
  children,
  isShowing,
}: Omit<CodeViewerUIProps, "onClose">) {
  return (
    <div
      className={cn(
        // Update top offset from 3.5rem to 4rem to match main content (pt-16)
        "fixed right-0 top-[4rem] bottom-[4.0rem] z-[100] flex w-[40%] shadow-lg rounded-tl-lg", // Added shadow-lg for visual lift
        "ease-in-out transition-transform duration-300", // Added transition for smoother animation
        isShowing ? "translate-x-0" : "translate-x-full"
      )}
    >
      <div className="h-full w-full bg-code-viewer border-default rounded-tl-lg"> {/* Changed bg-task-header to bg-code-viewer, added border-default and border-l */}
        {children}
      </div>
    </div>
  )
})

export default React.memo(function CodeViewerUI({
  children,
  isShowing,
  onClose,
}: CodeViewerUIProps) {
  const isMobile = useMediaQuery("(max-width: 768px)")

  return isMobile ? (
    <MobileView isShowing={isShowing} onClose={onClose}>
      {children}
    </MobileView>
  ) : (
    <DesktopView isShowing={isShowing}>{children}</DesktopView>
  )
})