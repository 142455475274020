import React from 'react';
import { cn } from '@/lib/utils';

interface ErrorBoundaryProps {
  children: React.ReactNode;
  className?: string;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('Error caught by boundary:', error, errorInfo);
  }

  handleReset = () => {
    this.setState({ hasError: false, error: null });
  };

  render() {
    if (this.state.hasError) {
      return (
        <div
          role="alert"
          className={cn(
            'min-h-[60vh] flex flex-col items-center justify-center p-4',
            'text-center font-mono',
            this.props.className
          )}
        >
          <div className="space-y-6 max-w-md">
            {/* Error Icon */}
            <div className="mx-auto w-12 h-12 rounded-full bg-red-50 dark:bg-red-950 
                          flex items-center justify-center">
              <span className="text-2xl text-red-500 dark:text-red-400">!</span>
            </div>

            {/* Error Message */}
            <div className="space-y-2">
              <h2 className="text-lg font-bold text-black dark:text-white">
                Something went wrong
              </h2>
              <p className="text-sm text-black/60 dark:text-white/60">
                {this.state.error?.message || 'An unexpected error occurred'}
              </p>
            </div>

            {/* Reset Button */}
            <button
              onClick={this.handleReset}
              className={cn(
                'px-4 py-2 rounded-lg font-mono text-sm transition-colors',
                'bg-black text-white hover:bg-black/90',
                'dark:bg-white dark:text-black dark:hover:bg-white/90',
                'focus:outline-none focus-visible:ring-2',
                'focus-visible:ring-black/20 dark:focus-visible:ring-white/20',
                'focus-visible:ring-offset-2',
                'focus-visible:ring-offset-white dark:focus-visible:ring-offset-zinc-900'
              )}
            >
              Try Again
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}