import { createHighlighter, type Highlighter } from "shiki/bundle/web";
import { useEffect, useState } from "react";

export default function SyntaxHighlighter({
  code,
  language,
}: {
  code: string;
  language: string;
}) {
  const [html, setHtml] = useState("");
  const [highlighter, setHighlighter] = useState<Highlighter | null>(null);

  // Load the highlighter and themes *once* on mount.
  useEffect(() => {
    async function initializeHighlighter() {
      // Determine dark mode *here*, during initialization
      const isDarkMode = document.documentElement.classList.contains("dark");

      const customLightTheme = {
        name: "custom-light",
        type: "light" as const,
        bg: "rgb(var(--code-viewer-bg))",
        fg: "rgb(var(--foreground))",
        settings: [
          { scope: "comment", settings: { foreground: "#6A9955" } },
          { scope: "punctuation", settings: { foreground: "rgb(var(--foreground))"} },
          { scope: "punctuation.definition.tag", settings: {foreground: "#808080"}},
          { scope: "constant.language", settings: { foreground: "#0077AA" } },
          { scope: "constant.numeric", settings: { foreground: "#098658" } },
          { scope: "keyword", settings: { foreground: "#0077AA" } },
          { scope: "storage.type", settings: { foreground: "#0077AA" } },
          { scope: "storage.modifier", settings: {foreground: "#0077AA"}},
          { scope: "entity.name.function", settings: { foreground: "#71539B" } },
          { scope: "support.function", settings: { foreground: "#71539B" } },
          { scope: "variable.parameter", settings: { foreground: "#AA5D00" } },
          { scope: "string", settings: { foreground: "#AA1111" } },
          { scope: "entity.name.tag", settings: { foreground: "#22863A" } },
          { scope: "entity.other.attribute-name", settings: { foreground: "#994500" } },
          { scope: "string.quoted.double", settings: { foreground: "#AA1111" } },
          { scope: "string.quoted.single", settings: { foreground: "#AA1111" } },
          { scope: "meta.embedded", settings: { foreground: "rgb(var(--foreground))" } },
          { scope: "meta.object-literal.key", settings: { foreground: "#3A4495" } },
          { scope: "variable.other.object", settings: {foreground: "rgb(var(--foreground))"}},
          { scope: "variable.other.readwrite", settings: { foreground: "rgb(var(--foreground))" } },
          { scope: "support.class", settings: { foreground: "#0077AA" } },
          { scope: "entity.name.type", settings: { foreground: "#0077AA" } },
        ],
      };

      const customDarkTheme = {
        name: "custom-dark",
        type: "dark" as const,
        bg: "rgb(var(--code-viewer-bg))",
        fg: "rgb(var(--foreground))",
        settings: [
          { scope: "comment", settings: { foreground: "#6A9955" } },
          { scope: "punctuation", settings: { foreground: "rgb(var(--foreground))"} },
          { scope: "punctuation.definition.tag", settings: {foreground: "#808080"}},
          { scope: "constant.language", settings: { foreground: "#56B6C2" } },
          { scope: "constant.numeric", settings: { foreground: "#B5CEA8" } },
          { scope: "keyword", settings: { foreground: "#569CD6" } },
          { scope: "storage.type", settings: { foreground: "#569CD6" } },
          { scope: "storage.modifier", settings: {foreground: "#569CD6"}},
          { scope: "entity.name.function", settings: { foreground: "#C586C0" } },
          { scope: "support.function", settings: { foreground: "#C586C0" } },
          { scope: "variable.parameter", settings: { foreground: "#9CDCFE" } },
          { scope: "string", settings: { foreground: "#CE9178" } },
          { scope: "entity.name.tag", settings: { foreground: "#569CD6" } },
          { scope: "entity.other.attribute-name", settings: { foreground: "#9CDCFE" } },
          { scope: "string.quoted.double", settings: { foreground: "#CE9178" } },
          { scope: "string.quoted.single", settings: { foreground: "#CE9178" } },
          { scope: "meta.embedded", settings: { foreground: "rgb(var(--foreground))" } },
          { scope: "meta.object-literal.key", settings: { foreground: "#9CDCFE" } },
          { scope: "variable.other.object", settings: {foreground: "rgb(var(--foreground))"}},
          { scope: "variable.other.readwrite", settings: { foreground: "rgb(var(--foreground))" } },
          { scope: "support.class", settings: { foreground: "#4EC9B0" } },
          { scope: "entity.name.type", settings: { foreground: "#4EC9B0" } },
        ],
      };

      const newHighlighter = await createHighlighter({
        langs: [
          "html",
          "css",
          "js",
          "javascript",
          "json",
          "jsx",
          "markdown",
          "python",
          "sql",
          "ts",
          "tsx",
          "typescript",
        ],
        themes: [], // Initialize with no themes
      });

      // Load the *initial* theme first, based on isDarkMode.  Still load BOTH.
      if (isDarkMode) {
        await newHighlighter.loadTheme(customDarkTheme);
        await newHighlighter.loadTheme(customLightTheme);
      } else {
        await newHighlighter.loadTheme(customLightTheme);
        await newHighlighter.loadTheme(customDarkTheme);
      }

      setHighlighter(newHighlighter); // Store the initialized highlighter
    }

    initializeHighlighter();
  }, []); // Empty dependency array: runs only on mount

  // Highlight code whenever the highlighter, code, or language changes
  useEffect(() => {
    if (highlighter && code && language) {
      const isDarkMode = document.documentElement.classList.contains("dark");
      const theme = isDarkMode ? "custom-dark" : "custom-light";

      const highlighted = highlighter.codeToHtml(code, {
        lang: language,
        theme: theme,
      });
      setHtml(highlighted);
    }
  }, [highlighter, code, language]); // Depend on the highlighter!

  return (
    <div
      className="p-4 text-sm font-mono overflow-x-auto"
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
}