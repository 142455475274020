import { useState, useEffect } from 'react';
import { cn } from '@/lib/utils';
import { TerminalText } from '@/components/ui/terminal-text';

interface LoadingProps {
  type?: 'content' | 'transition' | 'build';
  className?: string;
}

const LOADING_SEQUENCES = {
  content: [
    "I'm thinking...",
    "Hmm... what if...",
    "Oh! This is interesting...",
    "Just had a curious thought...",
    "Let me explore that...",
    "What a fascinating puzzle...",
    "I wonder... ah yes...",
    "Following this thread..."
  ],
  transition: [
    "Let's see where this leads...",
    "Ooh, this is promising...",
    "Connecting the dots...",
    "Almost there...",
    "Just one more piece..."
  ],
  build: [
    "I'm thinking...",
    "Analyzing requirements...",
    "Designing component structure...",
    "Setting up state management...",
    "Implementing core functionality...",
    "Adding interactive features...",
    "Optimizing performance...",
    "Polishing user interface...",
    "Running final checks..."
  ]
};

export function Loading({
  type = 'content',
  className
}: LoadingProps) {
  const [messageIndex, setMessageIndex] = useState(0);
  const messages = LOADING_SEQUENCES[type];
  const currentMessage = messages[messageIndex] ?? "Loading...";

  useEffect(() => {
    const interval = setInterval(() => {
      setMessageIndex(prev => (prev + 1) % messages.length);
    }, 3000); // Change message every 3 seconds

    return () => clearInterval(interval);
  }, [messages.length]);

  return (
    <div
      role="status"
      aria-label={currentMessage}
      className={cn(
        'font-mono text-base',
        className
      )}
    >
      <div className="min-h-[2rem]">
        <TerminalText
          content={currentMessage}
          animate={true}
          speed="normal"
          showCursor={true}
        />
      </div>

      {/* Screen reader text */}
      <span className="sr-only">
        {currentMessage}
      </span>
    </div>
  );
}