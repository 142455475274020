import React from "react"
import { X } from "lucide-react"
import { CheckIcon, CopyIcon } from "lucide-react"
import dedent from "dedent"
import { Button } from "./button"
import { SandpackProvider, SandpackPreview, useSandpack } from "@codesandbox/sandpack-react"
import { cn } from "../../lib/utils"
import * as shadcnComponents from "../../shadcn/shadcn"
import CodeViewerUI from "./code-viewer-ui"
import SyntaxHighlighter from "./syntax-highlighter"
import { TerminalText } from "./terminal-text"

const shadcnFiles = {
  "/lib/utils.ts": shadcnComponents.utils,
  "/components/ui/accordion.tsx": shadcnComponents.accordian,
  "/components/ui/alert-dialog.tsx": shadcnComponents.alertDialog,
  "/components/ui/alert.tsx": shadcnComponents.alert,
  "/components/ui/avatar.tsx": shadcnComponents.avatar,
  "/components/ui/badge.tsx": shadcnComponents.badge,
  "/components/ui/breadcrumb.tsx": shadcnComponents.breadcrumb,
  "/components/ui/button.tsx": shadcnComponents.button,
  "/components/ui/calendar.tsx": shadcnComponents.calendar,
  "/components/ui/card.tsx": shadcnComponents.card,
  "/components/ui/carousel.tsx": shadcnComponents.carousel,
  "/components/ui/checkbox.tsx": shadcnComponents.checkbox,
  "/components/ui/collapsible.tsx": shadcnComponents.collapsible,
  "/components/ui/dialog.tsx": shadcnComponents.dialog,
  "/components/ui/drawer.tsx": shadcnComponents.drawer,
  "/components/ui/dropdown-menu.tsx": shadcnComponents.dropdownMenu,
  "/components/ui/input.tsx": shadcnComponents.input,
  "/components/ui/label.tsx": shadcnComponents.label,
  "/components/ui/menubar.tsx": shadcnComponents.menuBar,
  "/components/ui/navigation-menu.tsx": shadcnComponents.navigationMenu,
  "/components/ui/pagination.tsx": shadcnComponents.pagination,
  "/components/ui/popover.tsx": shadcnComponents.popover,
  "/components/ui/progress.tsx": shadcnComponents.progress,
  "/components/ui/radio-group.tsx": shadcnComponents.radioGroup,
  "/components/ui/select.tsx": shadcnComponents.select,
  "/components/ui/separator.tsx": shadcnComponents.separator,
  "/components/ui/skeleton.tsx": shadcnComponents.skeleton,
  "/components/ui/slider.tsx": shadcnComponents.slider,
  "/components/ui/switch.tsx": shadcnComponents.switchComponent,
  "/components/ui/table.tsx": shadcnComponents.table,
  "/components/ui/tabs.tsx": shadcnComponents.tabs,
  "/components/ui/textarea.tsx": shadcnComponents.textarea,
  "/components/ui/toast.tsx": shadcnComponents.toast,
  "/components/ui/toaster.tsx": shadcnComponents.toaster,
  "/components/ui/toggle-group.tsx": shadcnComponents.toggleGroup,
  "/components/ui/toggle.tsx": shadcnComponents.toggle,
  "/components/ui/tooltip.tsx": shadcnComponents.tooltip,
  "/components/ui/use-toast.tsx": shadcnComponents.useToast,
  "/components/ui/index.tsx": `
  export * from "./button"
  export * from "./card"
  export * from "./input"
  export * from "./label"
  export * from "./select"
  export * from "./textarea"
  export * from "./avatar"
  export * from "./radio-group"
  `,
  "/public/index.html": dedent`
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Document</title>
        <script src="https://cdn.tailwindcss.com"></script>
      </head>
      <body>
        <div id="root"></div>
      </body>
    </html>
  `
}

const dependencies = {
  "lucide-react": "latest",
  recharts: "2.9.0",
  "react-router-dom": "latest",
  "@radix-ui/react-accordion": "^1.2.0",
  "@radix-ui/react-alert-dialog": "^1.1.1",
  "@radix-ui/react-aspect-ratio": "^1.1.0",
  "@radix-ui/react-avatar": "^1.1.0",
  "@radix-ui/react-checkbox": "^1.1.1",
  "@radix-ui/react-collapsible": "^1.1.0",
  "@radix-ui/react-dialog": "^1.1.1",
  "@radix-ui/react-dropdown-menu": "^2.1.1",
  "@radix-ui/react-hover-card": "^1.1.1",
  "@radix-ui/react-label": "^2.1.0",
  "@radix-ui/react-menubar": "^1.1.1",
  "@radix-ui/react-navigation-menu": "^1.2.0",
  "@radix-ui/react-popover": "^1.1.1",
  "@radix-ui/react-progress": "^1.1.0",
  "@radix-ui/react-radio-group": "^1.2.0",
  "@radix-ui/react-select": "^2.1.1",
  "@radix-ui/react-separator": "^1.1.0",
  "@radix-ui/react-slider": "^1.2.0",
  "@radix-ui/react-slot": "^1.1.0",
  "@radix-ui/react-switch": "^1.1.0",
  "@radix-ui/react-tabs": "^1.1.0",
  "@radix-ui/react-toast": "^1.2.1",
  "@radix-ui/react-toggle": "^1.1.0",
  "@radix-ui/react-toggle-group": "^1.1.0",
  "@radix-ui/react-tooltip": "^1.1.2",
  "class-variance-authority": "^0.7.0",
  clsx: "^2.1.1",
  "date-fns": "^3.6.0",
  "embla-carousel-react": "^8.1.8",
  "react-day-picker": "^8.10.1",
  "tailwind-merge": "^2.4.0",
  "tailwindcss-animate": "^1.0.7",
  "framer-motion": "^11.15.0",
  vaul: "^0.9.1"
}

interface CodeViewerProps {
  code: string
  language?: string
  isShowing: boolean
  onClose: () => void
  onRequestFix?: (error: string) => void
}

function ErrorMessage({ onRequestFix }: { onRequestFix: (error: string) => void }) {
  const { sandpack } = useSandpack()
  const [didCopy, setDidCopy] = React.useState(false)

  if (!sandpack.error) return null

  return (
    <div className="absolute inset-0 flex flex-col items-center justify-center gap-4 bg-white/5 backdrop-blur-sm">
      <div className="max-w-[400px] rounded-md bg-red-500 p-4 text-white shadow-xl shadow-black/20">
        <TerminalText content="Error" className="text-lg font-medium" />
        <pre className="mt-4 line-clamp-[10] overflow-x-auto whitespace-pre font-mono text-xs">
          {sandpack.error.message}
        </pre>
        <div className="mt-8 flex justify-between gap-4">
          <button
            onClick={async () => {
              if (!sandpack.error) return
              setDidCopy(true)
              await window.navigator.clipboard.writeText(sandpack.error.message)
              await new Promise((resolve) => setTimeout(resolve, 2000))
              setDidCopy(false)
            }}
            className="rounded border-red-300 px-2.5 py-1.5 text-sm font-semibold text-red-50"
          >
            {didCopy ? <CheckIcon size={18} /> : <CopyIcon size={18} />}
          </button>
          <button
            onClick={() => {
              if (!sandpack.error) return
              onRequestFix(sandpack.error.message)
            }}
            className="rounded bg-white px-2.5 py-1.5 text-sm font-medium text-black"
          >
            Try to fix
          </button>
        </div>
      </div>
    </div>
  )
}

function Preview({ code, onRequestFix }: { code: string; onRequestFix: (error: string) => void }) {
  return (
    <SandpackProvider
      key={code}
      theme="auto"
      template="react-ts"
      className="relative h-full w-full !h-full !grow [&_.sp-preview-container]:flex [&_.sp-preview-container]:h-full [&_.sp-preview-container]:w-full [&_.sp-preview-container]:grow [&_.sp-preview-container]:flex-col [&_.sp-preview-container]:justify-center [&_.sp-preview-iframe]:grow [&_.sp-preview-iframe]:!h-full [&_.sp-preview-iframe]:!min-h-0 [&_.sp-preview-iframe]:!block"
      files={{
        "App.tsx": code,
        ...shadcnFiles,
        "/tsconfig.json": {
          code: `{
            "include": [
              "./**/*"
            ],
            "compilerOptions": {
              "strict": true,
              "esModuleInterop": true,
              "lib": [ "dom", "es2015" ],
              "jsx": "react-jsx",
              "baseUrl": "./",
              "paths": {
                "@/components/*": ["components/*"]
              }
            }
          }
        `,
        },
      }}
      options={{
        externalResources: [
          "https://unpkg.com/@tailwindcss/ui/dist/tailwind-ui.min.css",
        ],
      }}
      customSetup={{
        dependencies,
      }}
    >
        <SandpackPreview
          showNavigator={false}
          showOpenInCodeSandbox={false}
          showRefreshButton={false}
          showRestartButton={false}
          showOpenNewtab={false}
          className="h-full w-full"
        />
        {onRequestFix && <ErrorMessage onRequestFix={onRequestFix} />}
    </SandpackProvider>
  )
}

export default function CodeViewer({
  code,
  language = "tsx",
  isShowing,
  onClose,
  onRequestFix,
}: CodeViewerProps) {
  const [activeTab, setActiveTab] = React.useState<"code" | "preview">("preview")
  const isReactCode = ["jsx", "tsx"].includes(language.toLowerCase())

  return (
    <CodeViewerUI isShowing={isShowing} onClose={onClose}>
      <div className="flex h-full flex-col font-mono bg-code-viewer rounded-tl-lg rounded-bl-lg">
        <div className="flex h-16 shrink-0 items-center justify-between border-b px-4 bg-code-viewer-header border-code-viewer-header rounded-tl-lg">
          <div className="inline-flex items-center gap-4">
            <Button
              variant="ghost"
              size="icon"
              onClick={onClose}
              className="text-code-viewer-header hover:text-foreground"
            >
              <X className="h-5 w-5" />
            </Button>
            <TerminalText content="App Viewer" className="text-sm text-code-viewer-header" />
          </div>

          {isReactCode && (
            <div className="inline-flex items-center gap-1 rounded-lg border p-1 border-code-viewer-tab-container">
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setActiveTab("code")}
                className={cn(
                  "h-7 w-16 text-xs font-mono",
                  activeTab === "code" && "bg-code-viewer-tab-active text-code-viewer-tab-active"
                )}
              >
                Code
              </Button>
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setActiveTab("preview")}
                className={cn(
                  "h-7 w-16 text-xs font-mono",
                  activeTab === "preview" && "bg-code-viewer-tab-active text-code-viewer-tab-active"
                )}
              >
                Preview
              </Button>
            </div>
          )}
        </div>

        <div className="flex grow flex-col overflow-y-auto rounded-bl-lg">
          <div className="flex min-h-full flex-col">
            {isReactCode ? (
              activeTab === "code" ? (
                <SyntaxHighlighter code={code} language={language} />
              ) : (
                <div className="flex grow">
                  <Preview code={code} onRequestFix={onRequestFix || (() => {})} />
                </div>
              )
            ) : (
              <SyntaxHighlighter code={code} language={language} />
            )}
          </div>
        </div>
      </div>
    </CodeViewerUI>
  )
}