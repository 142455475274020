import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { RootLayout } from '@/components/layout/root-layout';
import { TaskContent } from '@/components/task/TaskContent';
import { TaskSelector } from '@/components/task/TaskSelector';
import { ConversationHistory } from '@/components/game/conversation-history';
import { TerminalText } from '@/components/ui/terminal-text';
import { ChoiceButton } from '@/components/ui/choice-button';
import { useStore } from '@/lib/store';
import { type ProfessionalCategory, type TaskCategory } from '@/lib/task-types';
import { getTasksByCategory } from '@/lib/task-data';

// Professional categories
const PROFESSIONAL_CATEGORIES: readonly ProfessionalCategory[] = [
  {
    id: 'violin',
    label: 'Help with violin practice',
    description: 'Get personalized tips and guidance for your violin journey',
    examples: [
      'Create a practice routine that works for you',
      'Learn about different playing styles',
      'Get tips to improve your technique'
    ]
  },
  {
    id: 'cashier',
    label: 'Make your cashier job easier',
    description: 'Handle tricky situations at the register with confidence',
    examples: [
      'Deal with price questions smoothly',
      'Calculate complex discounts quickly',
      'Speed up your checkout process'
    ]
  },
  {
    id: 'social-worker',
    label: 'Social work support',
    description: 'Think through complex cases and spot hidden patterns',
    examples: [
      'Map out tricky case scenarios',
      'Find patterns across interventions',
      'Make documentation clearer'
    ]
  }
] as const;

// Welcome Page (Root Route)
function WelcomePage() {
  const navigate = useNavigate();
  const { setTaskCategory, setCurrentView, addMessage } = useStore();

  const handleCategorySelect = (categoryId: TaskCategory) => {
    const category = PROFESSIONAL_CATEGORIES.find(cat => cat.id === categoryId);
    if (!category) return;

    // Update state
    setTaskCategory(categoryId);
    setCurrentView('selection');

    // Add initial messages
    addMessage({
      role: 'user',
      content: `I want to focus on ${category.label.toLowerCase()}.`,
      metadata: {
        type: 'choice',
        taskId: categoryId
      }
    });

    const tasks = getTasksByCategory(categoryId);
    const taskExamples = tasks.slice(0, 3).map(task => task.name);

    addMessage({
      role: 'assistant',
      content: `Let's focus on ${category.label.toLowerCase()}.

Here are some tasks we'll cover:
${taskExamples.map(ex => `• ${ex}`).join('\n')}`,
      metadata: {
        type: 'response',
        taskId: categoryId,
        exportable: true,
        step: {
          number: 1,
          type: 'choice',
          prompt: `Let's start with ${category.label.toLowerCase()} tasks`
        }
      }
    });

    navigate('/assist');
  };

  return (
    <div className="space-y-6">
      <TerminalText
        content="Welcome, Mira. I'm Rainer! How can I help you today?"
        className="text-lg"
        speed="normal"
      />
      <div className="space-y-2">
        {PROFESSIONAL_CATEGORIES.map((category, index) => (
          <ChoiceButton
            key={category.id}
            indicator={String.fromCharCode(65 + index)}
            onClick={() => handleCategorySelect(category.id)}
          >
            <div>
              <div className="font-medium">{category.label}</div>
              <div className="text-sm text-subtitle">
                {category.description}
              </div>
              <div className="mt-2 space-y-1">
                {category.examples.map((example: string, i: number) => (
                  <div
                    key={i}
                    className="text-xs text-subheading"
                  >
                    • {example}
                  </div>
                ))}
              </div>
            </div>
          </ChoiceButton>
        ))}
      </div>
    </div>
  );
}

// Assist Page
function AssistPage() {
  const currentView = useStore(state => state.currentView);

  return (
    <div className="space-y-8">
      {currentView === 'selection' ? (
        <TaskSelector />
      ) : (
        <>
          <TaskContent />
          <ConversationHistory />
        </>
      )}
    </div>
  );
}

// Import pages
import LearnPage from '@/app/learn/page';
import BuildPage from '@/app/build/page';

export default function App() {
  return (
    <Routes>
      <Route path="/" Component={RootLayout}>
        <Route index Component={WelcomePage} />
        <Route path="assist" Component={AssistPage} />
        <Route path="build" Component={BuildPage} />
        <Route path="learn" Component={LearnPage} />
        <Route path="*" Component={() => <Navigate to="/" replace />} />
      </Route>
    </Routes>
  );
}