import { generateObject } from 'ai';
import { z } from 'zod';
import { type ConversationEntry } from './types';
import { type AIResponse } from './ai-types';
import { type Task } from './task-types';
import { createAnthropic } from '@ai-sdk/anthropic';
import { getTaskPrompt } from './prompts';

const MAX_STEPS = 7;

if (!import.meta.env.VITE_ANTHROPIC_API_KEY) {
  throw new Error('VITE_ANTHROPIC_API_KEY environment variable is required');
}

const anthropic = createAnthropic({
  apiKey: import.meta.env.VITE_ANTHROPIC_API_KEY,
  headers: { 'anthropic-dangerous-direct-browser-access': 'true' }
});

export const model = anthropic('claude-3-5-sonnet-20241022');

type InteractionType = 'choice' | 'file' | 'freeform';

const INTERACTION_TYPES: readonly InteractionType[] = ['choice', 'file', 'freeform'] as const;

type TaskContext = {
  taskId: string;
  turn: number;
  step?: {
    number: number;
    type: InteractionType;
    prompt: string;
  };
  conversationHistory: ConversationEntry[];
  interactionTypes: Set<InteractionType>;
};

function getGraduationChoices() {
  return [
    {
      id: 'graduate',
      text: 'Continue on Rainer',
      action: 'GRADUATE_TO_CLAUDE',
      consequence: 'Export conversation and continue with full Rainer capabilities'
    },
    {
      id: 'restart',
      text: 'Start Fresh (New Game+)',
      action: 'NEW_GAME_PLUS',
      consequence: 'Begin a new conversation with retained context'
    }
  ];
}

const aiResponseSchema = z.object({
  response: z.string()
    .describe('The main response content - provide clear value before asking the next question'),
  step: z.object({
    number: z.number()
      .min(1)
      .max(MAX_STEPS)
      .describe('Current turn number (1-7)'),
    type: z.enum(['choice', 'file', 'freeform'])
      .describe('The type of interaction for this turn, chosen based on context and task needs'),
    prompt: z.string()
      .describe('Clear, focused prompt for the chosen interaction type')
  }),
  choices: z.array(z.object({
    id: z.string(),
    text: z.string(),
    action: z.string(),
    consequence: z.string()
  }))
  .optional()
  .describe('Required when step.type is "choice". Provide 2-4 clear options with consequences.'),
  fileUpload: z.object({
    accept: z.array(z.string()),
    maxSize: z.number()
  })
  .optional()
  .describe('Required when step.type is "file". Always include a skip option in choices.')
}) satisfies z.ZodType<AIResponse>;

function isInteractionType(value: unknown): value is InteractionType {
  return typeof value === 'string' && INTERACTION_TYPES.includes(value as InteractionType);
}

function getDefaultInteractionType(
  task: Task,
  turn: number
): InteractionType {
  if (turn === 0) return 'choice';
  if (turn === MAX_STEPS - 1) return 'choice';
  
  switch (task.augmentation.type) {
    case 'validation':
      return 'choice';
    case 'exploration':
      return 'freeform';
    case 'refinement':
      return 'freeform';
    default:
      return 'choice';
  }
}

async function validateInteractionType(
  task: Task,
  context: TaskContext,
  type: InteractionType,
  turn: number
): Promise<boolean> {
  // Turn 7 must always be choice type with graduation options
  if (turn === MAX_STEPS) {
    return type === 'choice';
  }

  // Always allow any type if we haven't used it yet (except for turn 7)
  if (!context.interactionTypes.has(type) && turn < MAX_STEPS) {
    return true;
  }

  // Contextual validation based on task type and current state
  switch (task.augmentation.type) {
    case 'validation':
      // Prefer choices for validation tasks, but allow freeform for detailed explanations
      return type === 'choice' || (type === 'freeform' && context.interactionTypes.has('choice'));
    
    case 'exploration':
      // Prefer freeform and file for exploration, but ensure structure with choices
      return type === 'freeform' || type === 'file' || 
        (type === 'choice' && context.interactionTypes.has('freeform'));
    
    case 'refinement':
      // Allow any type for refinement, but ensure we've used freeform for detailed input
      return context.interactionTypes.has('freeform');
    
    default:
      return true;
  }
}

async function suggestInteractionType(
  task: Task,
  context: TaskContext,
  turn: number
): Promise<InteractionType> {
  // Turn 7 must always be choice
  if (turn === MAX_STEPS) {
    return 'choice';
  }

  // Get unused types (excluding turn 7)
  const unusedTypes = INTERACTION_TYPES.filter(type => !context.interactionTypes.has(type));
  
  // If we have unused types
  if (unusedTypes.length > 0) {
    const defaultType = getDefaultInteractionType(task, context.turn);
    
    // If our default type is unused, use it
    if (unusedTypes.includes(defaultType)) {
      return defaultType;
    }
    
    // Otherwise use the first unused type, with a fallback to the default type
    // This ensures we never return undefined
    return unusedTypes[0] ?? defaultType;
  }

  // If all types have been used, return based on task type and turn
  return getDefaultInteractionType(task, context.turn);
}

export async function generateTaskResponse(
  task: Task,
  userInput: string,
  context: string,
  file?: { data: Uint8Array; mimeType: string; prompt?: string }
): Promise<AIResponse> {
  // Initialize context with default values
  const parsedContext: TaskContext = {
    taskId: task.id,
    turn: 0,
    conversationHistory: [],
    interactionTypes: new Set()
  };
  
  try {
    // Parse actual context if available
    const contextData = JSON.parse(context);
    parsedContext.taskId = contextData.taskId;
    parsedContext.turn = contextData.turn || 0;
    parsedContext.step = contextData.step;
    parsedContext.conversationHistory = contextData.conversationHistory || [];
    parsedContext.interactionTypes = new Set(
      (contextData.interactionTypes || []).filter(isInteractionType)
    );
    
    const currentTurn = parsedContext.turn;
    const nextTurn = currentTurn + 1;
    const progress = Math.round((nextTurn / MAX_STEPS) * 100);

    // Generate initial response
    const response = await generateObject({
      model,
      schema: aiResponseSchema,
      messages: [
        {
          role: 'system',
          content: `${getTaskPrompt(task, progress)}

Turn ${nextTurn} of ${MAX_STEPS} (${progress}%)

Previous Interactions:
${parsedContext.conversationHistory?.map(entry => 
  entry.metadata?.step?.type ? `Turn ${entry.metadata.step.number}: ${entry.metadata.step.type}` : ''
).filter(Boolean).join('\n')}

Used Interaction Types: ${Array.from(parsedContext.interactionTypes).join(', ')}`
        },
        ...(parsedContext.conversationHistory || []).map((entry: ConversationEntry) => ({
          role: entry.role,
          content: entry.content
        })),
        {
          role: 'user',
          content: file ? [
            { type: 'text', text: file.prompt || 'Please analyze this file.' },
            { type: 'file', data: file.data, mimeType: file.mimeType }
          ] : userInput || 'Let\'s begin.'
        }
      ]
    });

    // Ensure we have a valid interaction type from the response
    const responseType = response.object.step.type;
    if (!isInteractionType(responseType)) {
      throw new Error(`Invalid interaction type: ${responseType}`);
    }

    // For turn 7, enforce graduation choices
    if (nextTurn === MAX_STEPS) {
      if (responseType !== 'choice') {
        // console.log('Final turn must be choice type, regenerating response...');  // Removed console.log
        return generateTaskResponse(task, userInput, context, file);
      }
      
      // Override with graduation choices
      response.object.choices = getGraduationChoices();
    }

    // Validate and potentially adjust interaction type
    const suggestedType = await suggestInteractionType(task, parsedContext, nextTurn);
    const isValidType = await validateInteractionType(task, parsedContext, responseType, nextTurn);

    if (!isValidType) {
      // Generate new response with suggested type
      // console.log(`Adjusting interaction type from ${responseType} to ${suggestedType}`); // Removed console.log
      return generateTaskResponse(task, userInput, JSON.stringify({
        ...parsedContext,
        suggestedType
      }), file);
    }

    // Update interaction types used
    parsedContext.interactionTypes.add(responseType);

    // Ensure file upload has reasonable size limit
    if (responseType === 'file' && response.object.fileUpload) {
      const minSize = 5 * 1024 * 1024; // 5MB
      if (!response.object.fileUpload.maxSize || response.object.fileUpload.maxSize < minSize) {
        response.object.fileUpload.maxSize = minSize;
      }
    }

    return response.object;
  } catch (error) {
    console.error('Error in generateTaskResponse:', error); // Kept this console.error as it's for actual errors

    return {
      response: "I apologize, but I encountered an error. Let's try again.",
      step: {
        number: Math.min(parsedContext.turn + 1, MAX_STEPS),
        type: 'choice' as const,
        prompt: 'Would you like to retry this step?'
      },
      choices: [{
        id: 'retry',
        text: 'Retry this step',
        action: 'retry',
        consequence: 'We\'ll try this interaction again'
      }]
    };
  }
}