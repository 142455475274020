import { Outlet } from 'react-router-dom';
import { ThemeProvider } from '@/components/theme-provider';
import { ErrorBoundary } from '@/components/error-boundary';
import { Header } from '@/components/layout/header';
import { Footer } from '@/components/layout/footer';
import { Analytics } from '@vercel/analytics/react';
import { cn } from '@/lib/utils';

interface RootLayoutProps {
  className?: string;
}

export function RootLayout({ className }: RootLayoutProps) {
  return (
    <ThemeProvider>
      <ErrorBoundary>
        <div className="min-h-screen bg-background">
          <Analytics />
          {/* Fixed Header */}
          <Header />

          {/* Scrollable Content Area */}
          <main className={cn(
            'pt-16 pb-24', // Space for fixed header and footer
            'min-h-screen overflow-auto'
          )}>
            <div className={cn(
              'mx-auto px-4',
              'max-w-[61.8ch]',
              className
            )}>
              <Outlet />
            </div>
          </main>

          {/* Fixed Footer */}
          <Footer />
        </div>
      </ErrorBoundary>
    </ThemeProvider>
  );
}
