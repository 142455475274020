import React from 'react';
import { type Task } from '@/lib/task-types';
import { type AIResponse, type Choice } from '@/lib/ai-types';
import { TerminalText } from '@/components/ui/terminal-text';
import { ChoiceButton } from '@/components/ui/choice-button';
import { FileDropzone } from '@/components/ui/file-dropzone';
import { generateTaskResponse } from '@/lib/claude';
import { prepareFileForClaude } from '@/lib/file-handler';
import { Loading } from '@/components/loading';
import { useStore } from '@/lib/store';
import { cn } from '@/lib/utils';
import { getTaskById } from '@/lib/task-data';

const MAX_STEPS = 7;

interface ContextData {
  taskId: string;
  turn: number;
  step?: {
    number: number;
    type: 'choice' | 'file' | 'freeform';
    prompt: string;
  };
  conversationHistory: any[];
}

function createContextData(task: Task | null, step: any, messages: any[], currentTask: string | null): ContextData | null {
  if (!task) return null;
  return {
    taskId: task.id,
    turn: step?.number || 0,
    step,
    conversationHistory: messages.filter(msg =>
      msg.metadata?.taskId === currentTask &&
      typeof msg.metadata?.turn === 'number' &&
      msg.metadata.turn <= (step?.number || 0)
    )
  };
}

interface InputAreaProps {
  task: Task;
  aiResponse: AIResponse;
  onChoice: (choice: { text: string; action: string }) => Promise<void>;
}

function FileUploadArea({ task, aiResponse, onChoice }: InputAreaProps) {
  const { setLoading, setError, addMessage, currentTurn } = useStore();
  const [isUploading, setIsUploading] = React.useState(false);

  if (!aiResponse.fileUpload) return null;

  return (
    <div className="space-y-4">
      {isUploading ? (
        <Loading type="transition" className="text-left" />
      ) : (
        <FileDropzone
          accept={aiResponse.fileUpload.accept}
          maxSize={aiResponse.fileUpload.maxSize}
          onFileSelect={async (file: File) => {
            try {
              setIsUploading(true);
              setLoading(true);
              const fileData = await prepareFileForClaude(file);
              const contextData = createContextData(task, aiResponse.step, [], task.id);
              if (!contextData) throw new Error('Failed to create context data');

              const response = await generateTaskResponse(task, '', JSON.stringify(contextData), {
                data: fileData.data,
                mimeType: 'application/pdf',
                prompt: 'Please analyze this file'
              });

              addMessage({
                role: 'user',
                content: `Uploaded file: ${file.name}`,
                metadata: {
                  type: 'file',
                  taskId: task.id,
                  step: aiResponse.step,
                  turn: currentTurn + 1
                }
              });

              addMessage({
                role: 'assistant',
                content: response.response,
                metadata: {
                  type: 'response',
                  exportable: true,
                  step: response.step,
                  taskId: task.id,
                  turn: response.step.number
                }
              });

              onChoice({ text: file.name, action: 'file_uploaded' });
            } catch (error) {
              console.error('Error handling file:', error);
              setError(error instanceof Error ? error.message : 'File upload failed');
            } finally {
              setIsUploading(false);
              setLoading(false);
            }
          }}
        />
      )}
      <ChoiceButton
        indicator="S"
        onClick={() => onChoice({ text: 'Skip file upload', action: 'skip' })}
      >
        <div className="space-y-2">
          <div className="font-medium">Skip file upload</div>
          <div className="text-sm text-subheading">
            Continue without uploading a file
          </div>
        </div>
      </ChoiceButton>
    </div>
  );
}

function FreeformArea({ aiResponse, onChoice }: InputAreaProps) {
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);

  const handleSubmit = async () => {
    const text = textareaRef.current?.value.trim();
    if (text) await onChoice({ text, action: text });
  };

  return (
    <div className="w-full space-y-2">
      <div className="flex items-baseline space-x-2">
        <span className="font-mono text-sm text-freeform-label">{'>'}</span>
        <span className="font-mono text-sm text-freeform-label-dimmed">Your response:</span>
      </div>
      <div className="relative">
        <textarea
          ref={textareaRef}
          className={cn(
            'w-full min-h-[120px] p-4 pr-24 bg-transparent font-mono text-base resize-none',
            'border-2 border-freeform-input focus:border-freeform-input-focus', // Use new utility classes
            'focus:outline-none',
            'placeholder-freeform-input',
          )}
          placeholder={aiResponse.step.prompt || "Type your response..."}
          onKeyDown={async (e) => {
            if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
              e.preventDefault();
              await handleSubmit();
            }
          }}
        />
        <button
          onClick={handleSubmit}
          className={cn(
            'absolute right-3 bottom-3',
            'px-3 py-2',
            'font-mono text-[15px] font-medium',
            'text-freeform-button hover:text-freeform-button-hover focus:text-freeform-button-focus',
            'focus:outline-none',
            'select-none cursor-pointer'
          )}
          aria-label="Send response"
        >
          SEND →
        </button>
      </div>
      <div className="flex justify-between items-center text-xs text-freeform-helper">
        <span>Press ⌘/Ctrl + Enter to submit</span>
        <span className="font-mono">or click SEND</span>
      </div>
    </div>
  );
}

function ChoiceArea({ aiResponse, onChoice }: InputAreaProps) {
  if (!aiResponse.choices) return null;

  // On turn 7, override with our predefined graduation choices
  if (aiResponse.step.number === MAX_STEPS) {
      const graduationChoices = [
      {
        id: 'graduate',
        text: 'Continue on Rainer',
        action: 'GRADUATE_TO_CLAUDE',
        consequence: 'Export conversation and continue with full Rainer capabilities'
      },
      {
        id: 'restart',
        text: 'Start Fresh (New Game+)',
        action: 'NEW_GAME_PLUS',
        consequence: 'Begin a new conversation with retained context'
      }
    ];

    return (
      <div className="space-y-2">
        {graduationChoices.map((choice, index) => (
          <ChoiceButton
            key={choice.id}
            indicator={String.fromCharCode(65 + index)}
            onClick={() => onChoice({ text: choice.text, action: choice.action })}
          >
            <div className="space-y-2">
              <div className="font-medium">{choice.text}</div>
              <div className="text-sm text-subtitle">
                {choice.consequence}
              </div>
            </div>
          </ChoiceButton>
        ))}
      </div>
    );
  }

  // For all other turns, use AI-provided choices
  return (
    <div className="space-y-2">
      {aiResponse.choices.map((choice: Choice, index: number) => (
        <ChoiceButton
          key={choice.id}
          indicator={String.fromCharCode(65 + index)}
          onClick={() => onChoice({ text: choice.action, action: choice.action })}
        >
          <div className="space-y-2">
            <div className="font-medium">{choice.text}</div>
            <div className="text-sm text-subheading">
              {choice.consequence}
            </div>
          </div>
        </ChoiceButton>
      ))}
    </div>
  );
}

function TaskHeader({ task, aiResponse }: { task: Task; aiResponse: AIResponse | null }) {
  return (
    <div
      className={cn(
        'font-mono text-sm bg-task-header p-4 rounded-lg space-y-4',
      )}
    >
      <div className="space-y-2">
        <div className="font-bold">{task.name}</div>
        <div className="text-subtitle">{task.description}</div>
      </div>
      {aiResponse && (
        <div className="relative h-6 mt-3" style={{ maxWidth: '16rem' }}>
          <div className="absolute inset-0 bg-progress-container rounded-lg" /> {/* Use new utility class */}
          <div
            className="absolute inset-y-0 left-0 bg-progress-fill transition-all duration-500 rounded-lg" // Use new utility class
            style={{ width: `${Math.round((aiResponse.step.number / MAX_STEPS) * 100)}%` }}
          />
          <div className="absolute inset-0 flex items-center justify-center">
            <span className="font-mono text-xs font-medium text-progress-label">
              {aiResponse.step.number} / {MAX_STEPS}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export function TaskContent({ className }: { className?: string }) {
  const {
    currentTask,
    messages,
    aiResponse,
    isLoading,
    error,
    setAIResponse,
    setLoading,
    setError,
    addMessage,
    handleAIChoice
  } = useStore();

  const initialized = React.useRef(false);
  const task = currentTask ? getTaskById(currentTask) : null;

  // Handle initial load
  React.useEffect(() => {
    async function loadContent(task: Task) {
      try {
        setLoading(true);
        const contextData = createContextData(task, undefined, messages, task.id);
        if (!contextData) throw new Error('Failed to create context data');

        const response = await generateTaskResponse(task, '', JSON.stringify(contextData));

        setAIResponse(response);
        addMessage({
          role: 'assistant',
          content: response.response,
          metadata: {
            type: 'response',
            exportable: true,
            step: response.step,
            taskId: task.id,
            turn: response.step.number
          }
        });
      } catch (error) {
        console.error('Error loading content:', error);
        setError(error instanceof Error ? error.message : 'Failed to load task content');
      } finally {
        setLoading(false);
      }
    }

    if (task && !initialized.current) {
      initialized.current = true;
      loadContent(task);
    }

    return () => {
      if (task?.id !== currentTask) {
        initialized.current = false;
      }
    };
  }, [task, messages, currentTask, addMessage, setAIResponse, setLoading, setError]);

  // Handle error state
  if (!task || error) {
    return (
      <div className="flex justify-center items-center h-full"> {/* Flex container for centering */}
        <div className="font-mono text-sm p-4 rounded-lg bg-error border border-error text-center"> {/* Centered text */}
          {error || 'No task selected'}
        </div>
      </div>
    );
  }

  // Render input area based on step type
  const renderInputArea = () => {
    if (!aiResponse) return null;

    const props = {
      task,
      aiResponse,
      onChoice: handleAIChoice
    };

    switch (aiResponse.step.type) {
      case 'file':
        return <FileUploadArea {...props} />;
      case 'freeform':
        return <FreeformArea {...props} />;
      case 'choice':
        return <ChoiceArea {...props} />;
      default:
        return null;
    }
  };

  return (
    <div className={cn('space-y-6', className)}>
      <TaskHeader task={task} aiResponse={aiResponse} />

      {isLoading ? (
        <div className="text-lg">
          <Loading type="content" className="text-left" />
        </div>
      ) : aiResponse ? (
        <div className="space-y-6">
          <TerminalText
            content={aiResponse.response}
            className="text-lg"
            speed="normal"
            showCursor={true}
          />
          <div className="space-y-2">
            {renderInputArea()}
          </div>
        </div>
      ) : null}
    </div>
  );
}