import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { type Message } from './types';
import { type AIResponse, type Step } from './ai-types';
import { type TaskCategory } from './task-types';
import { generateTaskResponse } from './claude';
import { getTaskById } from './task-data';

const MAX_STEPS = 7;

type View = 'selection' | 'task';

type StoreState = {
  currentTask: string | null;
  taskCategory: TaskCategory | null;
  userContext: Record<string, unknown>;
  currentStep: Step | undefined;
  currentTurn: number;
  messages: Message[];
  aiResponse: AIResponse | null;
  currentView: View;
  conversationCollapsed: boolean;
  isLoading: boolean;
  error: string | null;
};

type StoreActions = {
  setCurrentTask: (taskId: string | null) => void;
  setTaskCategory: (category: TaskCategory | null) => void;
  updateUserContext: (update: Partial<Record<string, unknown>>) => void;
  addMessage: (message: Omit<Message, 'id' | 'timestamp'>) => void;
  setAIResponse: (response: AIResponse | null) => void;
  handleAIChoice: (choice: { text: string; action: string }) => Promise<void>;
  handleNextStep: (action: string) => Promise<void>;
  setCurrentView: (view: View) => void;
  setConversationCollapsed: (collapsed: boolean) => void;
  toggleConversationCollapse: () => void;
  setLoading: (isLoading: boolean) => void;
  setError: (error: string | null) => void;
  reset: () => void;
};

const initialState: StoreState = {
  currentTask: null,
  taskCategory: null,
  userContext: {},
  currentStep: undefined,
  currentTurn: 0,
  messages: [],
  aiResponse: null,
  currentView: 'selection',
  conversationCollapsed: false,
  isLoading: false,
  error: null
};

export const useStore = create<StoreState & StoreActions>()(
  persist(
    (set, get) => ({
      ...initialState,

      setCurrentTask: (taskId) =>
        set((state) => ({
          ...state,
          currentTask: taskId,
          currentView: taskId ? 'task' : 'selection',
          currentStep: undefined,
          currentTurn: 0,
          messages: taskId ? state.messages.filter(msg => msg.metadata?.taskId === taskId) : [],
          aiResponse: null
        })),

      setTaskCategory: (category) =>
        set({ taskCategory: category, currentView: 'selection' }),

      updateUserContext: (update) =>
        set((state) => ({
          userContext: { ...state.userContext, ...update }
        })),

      addMessage: (message) =>
        set((state) => ({
          messages: [
            ...state.messages,
            {
              id: crypto.randomUUID(),
              timestamp: Date.now(),
              ...message,
              metadata: {
                ...message.metadata,
                taskId: state.currentTask || undefined,
                turn: state.currentTurn + 1
              }
            }
          ]
        })),

      setAIResponse: (response) =>
        set((state) => ({
          aiResponse: response,
          currentStep: response?.step,
          currentTurn: response?.step.number || state.currentTurn
        })),

      handleNextStep: async (action) => {
        const state = get();
        if (state.currentTurn >= MAX_STEPS) {
          switch (action) {
            case 'GRADUATE_TO_CLAUDE':
              await navigator.clipboard.writeText(
                state.messages
                  .filter(msg => msg.metadata?.exportable)
                  .map(msg => `${msg.role.toUpperCase()}: ${msg.content}`)
                  .join('\n\n')
              );
              window.open('https://thinkingmachines.ai/', '_blank');
              break;
            case 'NEW_GAME_PLUS':
              set((state) => ({
                ...initialState,
                userContext: state.userContext
              }));
              break;
          }
        }
      },

      handleAIChoice: async (choice) => {
        const state = get();
        if (!state.currentTask) return;

        try {
          set({ isLoading: true, error: null });
          const task = getTaskById(state.currentTask);
          if (!task) throw new Error('Task not found');

          // Add user message with turn number
          get().addMessage({
            role: 'user',
            content: choice.text,
            metadata: {
              type: 'choice',
              taskId: task.id,
              step: state.currentStep,
              turn: state.currentTurn + 1
            }
          });

          // Generate AI response
          // Filter conversation history to only include messages up to current turn
          const relevantHistory = state.messages.filter(msg =>
            msg.metadata?.taskId === state.currentTask &&
            typeof msg.metadata?.turn === 'number' &&
            msg.metadata.turn <= (state.currentTurn + 1)
          );

          const response = await generateTaskResponse(
            task,
            choice.action,
            JSON.stringify({
              taskId: state.currentTask,
              turn: state.currentTurn,
              step: state.currentStep,
              conversationHistory: relevantHistory
            })
          );

          // Update state with response
          set({ 
            aiResponse: response,
            currentStep: response.step,
            currentTurn: response.step.number
          });

          // Add AI message with turn number
          get().addMessage({
            role: 'assistant',
            content: response.response,
            metadata: {
              type: 'response',
              exportable: true,
              step: response.step,
              taskId: task.id,
              turn: response.step.number
            }
          });

          // Handle completion
          if (response.step.number === MAX_STEPS && response.choices?.[0]) {
            const finalChoice = response.choices[0];
            if (finalChoice.action === 'GRADUATE_TO_CLAUDE' || finalChoice.action === 'NEW_GAME_PLUS') {
              await get().handleNextStep(finalChoice.action);
            }
          }
        } catch (error) {
          set({ error: error instanceof Error ? error.message : 'Failed to handle AI choice' });
        } finally {
          set({ isLoading: false });
        }
      },

      setCurrentView: (view) => set({ currentView: view }),
      setConversationCollapsed: (collapsed) => set({ conversationCollapsed: collapsed }),
      toggleConversationCollapse: () => set((state) => ({ conversationCollapsed: !state.conversationCollapsed })),
      setLoading: (isLoading) => set({ isLoading }),
      setError: (error) => set({ error }),
      reset: () => set(initialState)
    }),
    {
      name: 'app-storage',
      version: 3,
      partialize: (state) => ({
        taskCategory: state.taskCategory,
        userContext: state.userContext,
        currentStep: state.currentStep,
        currentTurn: state.currentTurn,
        messages: state.messages,
        currentView: state.currentView,
        conversationCollapsed: state.conversationCollapsed
      })
    }
  )
);

export const useCurrentTask = () => useStore((state) => state.currentTask);
export const useTaskCategory = () => useStore((state) => state.taskCategory);
export const useUserContext = () => useStore((state) => state.userContext);
export const useMessages = () => useStore((state) => state.messages);
export const useAIResponse = () => useStore((state) => state.aiResponse);
export const useIsLoading = () => useStore((state) => state.isLoading);
export const useError = () => useStore((state) => state.error);
export const useCurrentStep = () => useStore((state) => state.currentStep);
export const useCurrentTurn = () => useStore((state) => state.currentTurn);
export const useCurrentView = () => useStore((state) => state.currentView);
export const useConversationCollapsed = () => useStore((state) => state.conversationCollapsed);
